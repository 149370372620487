import { RTKTags, api } from '@/store/api'

import Env from '../../../env/env'

import type {
  EnterpriseUserResponse,
  deleteEnterpriseUserRequest,
  GetEnterpriseRolesResponse,
  updateEnterpriseUserRoleRequest,
  inviteEnterpriseNewUserRequest,
  EnterpriseUserRequest,
  SocialCheckResponse,
  UpdateEnterpriseData,
  listOfInvitesResponse,
  listOfInvitesRequest,
  socialCheckCrudResponse,
  deleteInvitationUserRequest,
} from './Enterprise.types'

export const enterpriseApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getEnterpriseUser: builder.query<
      EnterpriseUserResponse,
      EnterpriseUserRequest
    >({
      query: (params) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/enterprises/users`,
        method: 'GET',
        params,
      }),
      keepUnusedDataFor: 0,
      providesTags: [RTKTags.enterpriseUsers],
    }),
    getEnterpriseRoles: builder.query<GetEnterpriseRolesResponse, void>({
      query: () => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/enterprises/configurations`,
        method: 'GET',
      }),
      keepUnusedDataFor: 0,
      providesTags: [RTKTags.enterpriseUsers],
    }),
    updateEnterpriseUserRole: builder.mutation<
      void,
      updateEnterpriseUserRoleRequest
    >({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/enterprises/users/${request.id}/change-role`,
        method: 'PATCH',
        body: {
          role: request.role,
        },
      }),
      invalidatesTags: [RTKTags.enterpriseUsers],
    }),
    deleteEnterpriseUser: builder.mutation<void, deleteEnterpriseUserRequest>({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/enterprises/users/${request.userId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [RTKTags.enterpriseUsers],
    }),
    inviteEnterpriseNewUser: builder.mutation<
      void,
      inviteEnterpriseNewUserRequest
    >({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/enterprises/send-invite`,
        method: 'POST',
        body: {
          email: request.email,
          role: request.role,
        },
      }),
      invalidatesTags: [RTKTags.enterpriseUsers],
    }),

    socialChecksEnterprise: builder.query<SocialCheckResponse, void>({
      query: () => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/enterprises/social-checks`,
        method: 'GET',
      }),
      transformResponse: (response: socialCheckCrudResponse) => {
        return response.info.map((element) => {
          return {
            type: 'socialCheck',
            creation_date: element.reportDate,
            data: element,
          }
        })
      },
      keepUnusedDataFor: 0,
      providesTags: [RTKTags.monitoringCenter],
    }),

    updateEnterpriseData: builder.mutation<void, UpdateEnterpriseData>({
      query: (request: UpdateEnterpriseData) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/enterprises/${request.enterpriseId}`,
        method: 'PUT',
        body: {
          companyName: request.companyName,
          companyAddress: request.companyAddress,
        },
      }),
      invalidatesTags: [RTKTags.enterpriseInfo],
    }),
    listOfInvites: builder.query<listOfInvitesResponse, listOfInvitesRequest>({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/enterprises/invites/${request.userId}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 0,
      providesTags: [RTKTags.enterpriseUsers],
    }),
    deleteInvitationUser: builder.mutation<void, deleteInvitationUserRequest>({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/enterprises/invitations/${request.inviteMemberId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [RTKTags.enterpriseUsers],
    }),
  }),
})

export const {
  useGetEnterpriseUserQuery,
  useGetEnterpriseRolesQuery,
  useUpdateEnterpriseUserRoleMutation,
  useDeleteEnterpriseUserMutation,
  useInviteEnterpriseNewUserMutation,
  useSocialChecksEnterpriseQuery,
  useUpdateEnterpriseDataMutation,
  useListOfInvitesQuery,
  useDeleteInvitationUserMutation,
} = enterpriseApi
