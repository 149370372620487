import { Button } from '@/components/ui/button'
import { useNavigate } from 'react-router-dom'
import { ConsentFilesSheet } from './ConsentFilesSheet'
import Burgermenu from '@/assets/burgerMenu.svg?react' // El ícono del menú hamburguesa
import { useTranslation } from 'react-i18next'
import { AppRoutes } from '@/constants/appRoutes'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'

interface BurgerMenuProps {
  data: any
  isLoading: boolean
  name: string
  onPressDownload: () => {}
}

export const BurgerMenu = ({
  data,
  isLoading,
  onPressDownload,
  name,
}: BurgerMenuProps) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button className="flex items-center justify-center" variant="ghost">
          <Burgermenu color={weCheckPalette.neutralColor} />
        </Button>
      </PopoverTrigger>

      <PopoverContent className="w-40 p-0 border-2 border-baseColorHighlight bg-baseColor rounded-md">
        <div className="flex flex-col justify-start items-start">
          <p className="w-full pl-2 py-2 border-b-2 border-baseColorHighlight">
            {name}
          </p>

          {!data.hiddenDigitalReport && (
            <Button
              variant="ghost"
              onClick={() =>
                navigate(AppRoutes.reportGeneralInfo, { state: data.id })
              }
              className="w-full rounded-none pl-2 justify-start"
            >
              {t('dashboard.reportsScreen.view')}
            </Button>
          )}

          <Button
            asChild
            size={'default'}
            isLoading={isLoading}
            variant="ghost"
            onClick={onPressDownload}
            className="w-full rounded-none pl-2 justify-start cursor-pointer"
          >
            <div className="flex flex-row gap-4">
              <p>{t('dashboard.reportsScreen.download')}</p>
            </div>
          </Button>

          {data.complianceSigned && (
            <div className="w-full">
              <ConsentFilesSheet
                data={data}
                isLoading={isLoading}
                variant={'ghost'}
                paddingLeft={8}
                customWidth="100%"
                customJustifyContent="flex-start"
              />
            </div>
          )}
        </div>
      </PopoverContent>
    </Popover>
  )
}
