import { InfoCircle } from 'iconsax-react'
import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet'
import useMobileMediaQuery from '@/hooks/useMobileMediaQuery'
import type { Inputs } from '@/store/api/Person/Person.types'

import { InputCard } from '../../../components/InputCard/InputCard'

interface SubjectInputsSheetTypes {
  subjectInputs: Inputs | undefined
}
export const SubjectInputsSheet = ({
  subjectInputs,
}: SubjectInputsSheetTypes) => {
  const { t } = useTranslation()
  const isMobile = useMobileMediaQuery()
  return (
    <Sheet>
      <SheetTrigger>
        <InfoCircle
          size="20"
          color="#037797"
          variant="Outline"
          className="mt-1"
        />
      </SheetTrigger>
      <SheetContent
        className="flex flex-col gap-8 overflow-y-scroll"
        side={isMobile ? 'bottom' : 'right'}
      >
        <SheetHeader className="flex flex-col gap-5">
          <SheetTitle className="text-textNormal text-xl font-medium tracking-wide">
            {t('dashboard.header.subjectInputs')}
          </SheetTitle>
          <SheetDescription className="text-textAccent text-base font-normal tracking-tight max-md:text-left">
            {t('dashboard.header.theFollowingDetails')}
          </SheetDescription>
        </SheetHeader>
        <div className="flex flex-col justify-center items-start gap-5">
          <InputCard
            input={t('dashboard.header.name')}
            content={subjectInputs?.name ?? 'N/A'}
          />
          {(subjectInputs?.city ||
            subjectInputs?.state ||
            subjectInputs?.country) && (
            <InputCard
              input={t('dashboard.header.location')}
              content={`${subjectInputs?.city ?? 'N/A'}, ${
                subjectInputs?.state ?? 'N/A'
              }, ${subjectInputs?.country ?? 'N/A'}`}
            />
          )}
          {subjectInputs?.college && (
            <InputCard
              input={t('dashboard.header.college')}
              content={subjectInputs?.college ?? 'N/A'}
            />
          )}

          {subjectInputs?.phone && (
            <InputCard
              input={t('dashboard.header.phoneNumber')}
              content={subjectInputs?.phone ?? 'N/A'}
            />
          )}

          {subjectInputs?.nickname && (
            <InputCard
              input={t('dashboard.header.alias')}
              content={subjectInputs?.nickname ?? 'N/A'}
            />
          )}
          {subjectInputs?.ageOrDateOfBirth && (
            <InputCard
              input={t('dashboard.header.age')}
              content={subjectInputs?.ageOrDateOfBirth ?? 'N/A'}
            />
          )}
          {subjectInputs?.topics.length !== 0 &&
            subjectInputs?.topics.map((topic, index) => (
              <InputCard
                input={`${t('dashboard.header.topic')} ${index + 1}`}
                content={topic}
                key={index}
              />
            ))}
        </div>
      </SheetContent>
    </Sheet>
  )
}
