import { useTranslation } from 'react-i18next'

import { Error404 } from '@/components/ErrorState/Error404'

import { EmptyState } from '../EmptyState/EmptyState'
import { LottieSpinner } from '../LottieSpinner/LottieSpinner'
import EmptyStateAsset from '@/assets/emptyState/emptyStateAsset.svg?react'

import { WeScreenHeader } from './WeScreenHeader'

export const WeScreenV2 = ({
  children,
  customGap = 6,
  customHeaderGap = 16,
  useHeader = true,
  title,
  useHeaderBackButton = true,
  rightSideHeaderContent,
  useNextButton = true,
  onClickNextButton,
  isLoadingNextButton,
  isDisabledNextButton,
  onClickBack,
  isLoading = false,
  isError = false,
  isEmptyState = false,
  emptyStateText,
  emptyStateAlternativeAsset,
}: {
  children?: React.ReactNode
  customGap?: number
  customHeaderGap?: number
  useHeader?: boolean
  title?: string
  useHeaderBackButton?: boolean
  rightSideHeaderContent?: React.ReactNode
  useNextButton?: boolean
  onClickNextButton?: (key: any) => void
  isLoadingNextButton?: boolean
  isDisabledNextButton?: boolean
  onClickBack?: () => void
  isLoading?: boolean
  isError?: boolean
  isEmptyState?: boolean
  emptyStateText?: string
  emptyStateAlternativeAsset?: React.ReactNode
}) => {
  const { t } = useTranslation()
  const screenId = title?.replace(/\s/g, '')
  return (
    <div
      className={`flex flex-col w-full h-full`}
      style={{ gap: customGap }}
      key={`screen-${screenId}`}
    >
      {useHeader && (
        <WeScreenHeader
          useContinueButton={useNextButton}
          disabled={isDisabledNextButton}
          isLoading={isLoadingNextButton}
          onClickNext={onClickNextButton}
          title={title}
          useBackButton={useHeaderBackButton}
          customButton={rightSideHeaderContent}
          onClickBack={onClickBack}
          customHeaderGap={customHeaderGap}
        />
      )}
      {isError && (
        <div
          className="flex justify-center items-center w-full h-full"
          key={`error-${screenId}`}
        >
          <Error404
            title={t('dashboard.error404.unexpectedError')}
            text={t('dashboard.error404.weReSorry')}
            absolute={false}
          />
        </div>
      )}
      {isLoading && (
        <div
          className="flex justify-center items-center w-full h-full"
          key={`loader-${screenId}`}
        >
          <LottieSpinner
            width={100}
            height={100}
            text={t('dashboard.loading.loading')}
          />
        </div>
      )}
      {isEmptyState && (
        <div
          className="flex justify-center items-center w-full h-full"
          key={`empty-${screenId}`}
        >
          <EmptyState text={emptyStateText} />
        </div>
      )}
      {!isError && !isLoading && !isEmptyState && children}
    </div>
  )
}
