import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Avatar, AvatarFallback } from '@/components/ui/avatar'
import { Chip } from '@/pages/Home/components/Chip/Chip'
import { selectUserInfo } from '@/store/api/Users/Users.selector'

import { MembersRoleSelect } from './MembersRoleSelect'
import { RemoveMembersDialog } from './RemoveMembersDialog'
import useSmallMobileMediaQuery from '@/hooks/useSmallMobileMediaQuery'

interface TeamMembersItemTypes {
  name: string
  address: string
  role: string
  id: string
  availableRoles: string[] | undefined
  isLoggedUser?: boolean
}

export const TeamMembersItem = ({
  name,
  address,
  role,
  id,
  availableRoles,
  isLoggedUser,
}: TeamMembersItemTypes) => {
  const { t } = useTranslation()
  const isSmallMobile = useSmallMobileMediaQuery()
  const initials = useMemo(() => {
    if (!name) {
      return ''
    }
    const fullname = name
    const initials = fullname.split(' ')
    const firstInitial = initials[0] ? initials[0][0].toUpperCase() : ''
    const secondInitial = initials[2] ? initials[2][0].toUpperCase() : ''
    return `${firstInitial} ${secondInitial}`
  }, [name])

  const userInfo = useSelector(selectUserInfo)

  const canChangeRol =
    (role === 'view_user' &&
      (userInfo.role === 'manager' || userInfo.role === 'owner')) ||
    (role === 'operational_user' &&
      (userInfo.role === 'manager' || userInfo.role === 'owner')) ||
    (role === 'manager' && userInfo.role === 'owner')

  return (
    <div className="bg-baseColor rounded-lg flex justify-center lg:justify-between xl:justify-normal p-3 mb-4 items-center max-md:flex-col gap-4 md:h-16 max-md:h-32">
      <div className="flex w-1/3 max-md:w-full">
        <div className="pr-3">
          <Avatar>
            {name && (
              <AvatarFallback className="bg-baseColor">
                {initials}
              </AvatarFallback>
            )}
          </Avatar>
        </div>
        <div className="flex flex-col">
          <div className="flex gap-2 items-center">
            <p className="font-medium tracking-wide">{name}</p>
            {isLoggedUser && <Chip text={t('dashboard.team.you')} green />}
          </div>
          <p className="text-sm font-normal tracking-wide break-words">
            {address}
          </p>
        </div>
      </div>
      <div className="flex md:w-2/3 w-full items-center justify-end md:justify-between gap-4">
        <div className="w-full md:w-auto flex items-center justify-between md:justify-end">
          {!isLoggedUser && canChangeRol ? (
            <MembersRoleSelect
              actualRole={role}
              id={id}
              availableRoles={availableRoles}
            />
          ) : (
            <div
              className={`rounded-xl w-full h-10 text-center border border-primaryColor30 bg-primaryColor5 px-3 py-2`}
            >
              <p className="capitalize">
                {(role === 'operational_user' && 'Operational') ||
                  (role === 'view_user' && 'Reader') ||
                  (role === 'manager' && 'Manager') ||
                  role}
              </p>
            </div>
          )}
        </div>
        {!isLoggedUser && canChangeRol && (
          <RemoveMembersDialog
            name={name}
            address={address}
            initials={initials}
            id={id}
          />
        )}
      </div>
    </div>
  )
}
