import { api } from '@/store/api'

import Env from '../../../env/env'

import type {
  CreateCheckoutSessionReponse,
  CreateCheckoutSessionTypes,
  PlanHistoryCrudresponse,
  PlanHistoryRequest,
  PlanHistoryResponse,
} from './Plan.types'

export const planApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createCheckoutSession: builder.mutation<
      CreateCheckoutSessionReponse,
      CreateCheckoutSessionTypes
    >({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v3/plan/one-time/checkout`,
        method: 'POST',
        body: {
          ...request,
        },
      }),
    }),
    planHistory: builder.query<PlanHistoryResponse, PlanHistoryRequest>({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v2/enterprises/recharge-history`,
        method: 'GET',
        params: {
          page: request.page,
          pageSize: request.pageSize,
        },
      }),
      transformResponse: (response: PlanHistoryCrudresponse) => {
        if (response.info && response.info.rechargeHistory.length) {
          const sortedRechargeHistory = response.info.rechargeHistory.sort(
            (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime(),
          )
          return {
            ...response.info,
            rechargeHistory: sortedRechargeHistory,
          }
        }
        return response.info
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        const { page, pageSize } = queryArgs
        return `${endpointName}:${page}:${pageSize}`
      },

      merge: (currentCache, newItems) => {
        const { rechargeHistory } = newItems

        if (!rechargeHistory.length) {
          return currentCache
        }

        return {
          ...newItems,
          rechargeHistory: [
            ...(currentCache?.rechargeHistory || []),
            ...rechargeHistory,
          ],
        }
      },

      keepUnusedDataFor: 0,
    }),
  }),
})

export const { useCreateCheckoutSessionMutation, useLazyPlanHistoryQuery } =
  planApi
