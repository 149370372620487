import { useEffect, useState } from 'react'

import { Button } from '@/components/ui/button'

export const ForceReloadCard = () => {
  const [visible, setVisible] = useState(true)
  const [hideTemporarily, setHideTemporarily] = useState(false)
  const [closing, setClosing] = useState(false)
  const [opening, setOpening] = useState(true)

  useEffect(() => {
    if (hideTemporarily) {
      const timer = setTimeout(() => {
        setVisible(true)
        setHideTemporarily(false)
        setOpening(true)
      }, 900000)

      return () => clearTimeout(timer)
    }
  }, [hideTemporarily])

  useEffect(() => {
    if (visible) {
      const timer = setTimeout(() => {
        setOpening(false)
      }, 500)
      return () => clearTimeout(timer)
    }
  }, [visible])

  const handleCancel = () => {
    setClosing(true)
    setTimeout(() => {
      setVisible(false)
      setClosing(false)
      setHideTemporarily(true)
    }, 500)
  }

  const handleReload = () => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .getRegistrations()
        .then(function (registrations) {
          for (const registration of registrations) {
            registration.unregister()
          }
        })
        .then(() => {
          window.location.reload()
        })
    } else {
      window.location.reload()
    }
  }

  if (!visible) {
    return null
  }

  return (
    <div
      className={`bg-primaryColor5 backdrop-blur-2xl flex lg:flex-row flex-col justify-between items-center p-3 
          ${
            opening
              ? 'transition-all duration-500 transform -translate-y-10 opacity-0'
              : closing
                ? 'transition-all duration-500 transform -translate-y-10 opacity-0'
                : 'transition-all duration-500 transform translate-y-0 opacity-100'
          }`}
    >
      <p>New Version Available! Reload to get the latest updates</p>
      <div className="flex flex-row lg:gap-3 gap-5 mt-2 lg:mt-0">
        <Button onClick={handleReload}>Reload</Button>
        <Button variant="secondary" onClick={handleCancel}>
          Cancel
        </Button>
      </div>
    </div>
  )
}
