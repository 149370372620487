import { Link } from 'react-router-dom'

import type { SidebarItemTypes } from './SidebarItem.types'

export const SidebarItem = ({
  isActive,
  name,
  linkUrl,
  disabled,
  customOnClick,
}: SidebarItemTypes) => {
  return (
    <div
      className={`flex flex-row p-2 h-10 rounded-md bg-baseColor ${
        isActive &&
        !disabled &&
        'transition-all duration-1500 bg-primaryColor px-4 '
      }${!disabled && !isActive && 'bg-baseColor hover:bg-primaryColor30'}
        ${disabled && 'bg-baseColor hover:cursor-not-allowed'}`}
    >
      {disabled && (
        <div className="flex flex-row ">
          <p
            className={`text-xs leading-6 text-neutralColor30 font-medium transition duration-1000 opacity-100`}
          >
            {name}
          </p>
        </div>
      )}

      {!disabled && (
        <Link
          to={linkUrl}
          className="flex flex-row w-full h-full items-center"
          role="button"
          onClick={customOnClick}
        >
          <p
            className={`text-xs font-medium ${
              isActive ? 'text-staticWhite' : 'text-neutralColor'
            } opacity-100`}
          >
            {name}
          </p>
        </Link>
      )}
    </div>
  )
}
