/* eslint-disable jsx-a11y/tabindex-no-positive */
import { zodResolver } from '@hookform/resolvers/zod'
import useMask from '@react-input/mask/useMask'
import { isEmpty } from 'ramda'
import { useEffect, useMemo, useState } from 'react'
import Autocomplete from 'react-google-autocomplete'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Progress } from '@/components/ui/progress'
import { WeScreenV2 } from '@/components/WeScreen/WeScreenV2'
import { AppRoutes } from '@/constants/appRoutes'
import { useAppDispatch, useAppSelector } from '@/hooks/redux'
import useMobileMediaQuery from '@/hooks/useMobileMediaQuery'
import { setFinalStepGuardToken } from '@/store/api/NewSearch/NewSeachGuardToken.slice'
import {
  selectNewSearch,
  selectHasSocialMediaData,
  selectSubjectInfo,
} from '@/store/api/NewSearch/NewSearch.selector'
import { setSubjectInfo } from '@/store/api/NewSearch/NewSearch.slice'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'
import type { newSearchSubjectInfoType } from '@/utils/schemas'
import { newSearchSubjectInfoSchema } from '@/utils/schemas'

import Env from '../../env/env'
import { SocialMediaSelector } from '../Reports/Components/SocialMediaSelector'

import { Stepper } from './Components/Stepper'
import { useHasCurrentStepToken } from './Guards/useNewSearchSecondStepGuard'

export const NewSearchSubjectInfo = () => {
  useHasCurrentStepToken('subjectInfoStep')
  const { t } = useTranslation()
  const key = Env.GOOGLE_KEY as string
  const subjectInfo = useAppSelector(selectSubjectInfo)

  const [countryShortName, setCountryShortName] = useState(
    subjectInfo.countryShortName,
  )
  const [stateShortName, setStateShortName] = useState(
    subjectInfo.stateShortName,
  )
  const [cityShortName, setCityShortName] = useState(subjectInfo.cityShortName)

  const form = useForm<newSearchSubjectInfoType>({
    resolver: zodResolver(newSearchSubjectInfoSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      countryLongName: '',
      email: '',
      middleName: '',
      alias: '',
      phoneNumber: '',
      dob: '',
      collage: '',
      companyName: '',
      cityLongName: '',
      stateLongName: '',
    },
    mode: 'onChange',
  })

  const hasSocialMediaData = useSelector(selectHasSocialMediaData)

  const {
    handleSubmit,
    control,
    formState: { errors, dirtyFields },
    resetField,
    watch,
    setValue,
  } = form

  useEffect(() => {
    Object.keys(subjectInfo).forEach((key) => {
      const subjectInfoCast = subjectInfo as any
      if (subjectInfoCast[key] !== '') {
        setValue(key as keyof newSearchSubjectInfoType, subjectInfoCast[key], {
          shouldDirty: true,
          shouldTouch: true,
          shouldValidate: true,
        })
      }
    })
  }, [subjectInfo, setValue])

  const searchType = useSelector(selectNewSearch).selectSearchType.searchType

  const steps = [
    searchType === 'ai_report' || searchType === 'adverse_media_report'
      ? undefined
      : t('dashboard.newSearch.common.focusedTopics'),
    t('dashboard.newSearch.common.subjectInformation'),
    t('dashboard.newSearch.common.submit'),
  ]

  const activeStep =
    searchType === 'ai_report' || searchType === 'adverse_media_report' ? 0 : 1

  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const isMobile = useMobileMediaQuery()

  const onSubmit = (data: any) => {
    dispatch(
      setSubjectInfo({
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        middleName: data.middleName,
        alias: data.alias,
        phoneNumber: data.phoneNumber,
        dob: data.dob,
        collage: data.collage,
        companyName: data.companyName,
        countryLongName: data.countryLongName,
        countryShortName: countryShortName,
        stateLongName: data.stateLongName,
        stateShortName: stateShortName,
        cityLongName: data.cityLongName,
        cityShortName: cityShortName,
      }),
    )
    dispatch(setFinalStepGuardToken({ finalStepGuardToken: true }))
    navigate(AppRoutes.newSearchLegalDisclaimer)
  }

  const dateFormat = useMask({
    mask: '__/__/____',
    replacement: { _: /\d/ },
  })

  const requiredFields: (keyof newSearchSubjectInfoType)[] = [
    'firstName',
    'lastName',
    'email',
    'countryLongName',
    'stateLongName',
    'cityLongName',
  ]
  const formWatch = watch()

  const completePercentage = useMemo(() => {
    const formValues = form.getValues() as any
    const totalFields = Object.keys(formValues).length + 1

    const dirtyFieldsKeys = Object.keys(dirtyFields).filter(
      (item) => item !== 'socialMediaUrl',
    )

    const completedFields = dirtyFieldsKeys.filter((field: any) => {
      return formValues[field] !== ''
    })

    const errorFields = Object.keys(errors)

    const areAllErrorsCompleted = errorFields.every((field) =>
      completedFields.includes(field),
    )

    if (areAllErrorsCompleted && !isEmpty(errorFields)) {
      return ((completedFields.length - errorFields.length) / totalFields) * 100
    }
    const numberOfCompletedFields = hasSocialMediaData
      ? completedFields.length + 1
      : completedFields.length

    return (numberOfCompletedFields / totalFields) * 100
  }, [form, dirtyFields, formWatch, errors, hasSocialMediaData])

  const areRequiredFieldsValid = (
    formValues: newSearchSubjectInfoType,
  ): boolean => {
    return requiredFields.every((field) => {
      const value = formValues[field]
      return value !== undefined && value !== null && value !== ''
    })
  }

  const inputStrength = useMemo(() => {
    if (completePercentage < 20 || !areRequiredFieldsValid(formWatch)) {
      return {
        text: t('dashboard.newSearch.common.insufficient'),
        color: weCheckPalette.neutralColor60,
      }
    }
    if (completePercentage < 40) {
      return {
        text: t('dashboard.newSearch.common.essential'),
        color: weCheckPalette.dynamicBlue,
      }
    } else if (completePercentage < 70) {
      return {
        text: t('dashboard.newSearch.common.advanced'),
        color: weCheckPalette.dynamicBlue,
      }
    } else {
      return {
        text: t('dashboard.newSearch.common.precision'),
        color: weCheckPalette.dynamicGreen,
      }
    }
  }, [completePercentage, formWatch, subjectInfo])

  useEffect(() => {
    const phoneNumber = form.getValues().phoneNumber
    const dirtyFieldsKeys = Object.keys(dirtyFields)

    if (dirtyFieldsKeys.includes('phoneNumber') && !phoneNumber) {
      resetField('phoneNumber')
    }
  }, [dirtyFields, formWatch])

  return (
    <WeScreenV2
      onClickNextButton={handleSubmit(onSubmit)}
      isDisabledNextButton={!form.formState.isValid}
    >
      <Form {...form}>
        <form className="h-full flex flex-col">
          <div className="flex flex-col items-center">
            <div className="mt-8 mb-8">
              <Stepper steps={steps} activeStep={activeStep} />
            </div>
          </div>
          <div className="my-6 mx-6 flex flex-col justify-between flex-1">
            {isMobile && (
              <div className="flex flex-col gap-2">
                <div className="flex justify-between items-center">
                  <div className="flex items-center justify-center">
                    <p className="">
                      {t('dashboard.newSearch.common.inputStrength')}
                    </p>
                    <p
                      className="text-textAccent ml-2 font-medium"
                      style={{ color: inputStrength.color }}
                    >
                      {inputStrength.text}
                    </p>
                  </div>
                  <span className="text-neutralColor60">
                    {t('dashboard.newSearch.common.estimateAccuracy')}
                    <span className="text-neutralColor font-bold ml-1">
                      {completePercentage.toFixed(0)} {'%'}
                    </span>
                  </span>
                </div>

                <Progress
                  value={completePercentage}
                  className="h-4"
                  color={inputStrength.color}
                />
              </div>
            )}
            <div className="flex flex-col gap-4 mt-8 lg:mt-0">
              <p className="text-base font-medium">
                {t('dashboard.newSearch.stepSubjectInfo.personalInformation')}
              </p>
              <div className="flex lg:flex-row flex-col gap-6">
                <FormField
                  control={control}
                  name="firstName"
                  render={({ field }) => (
                    <FormItem className="w-full h-20">
                      <FormLabel>
                        <span className="text-neutralColor">
                          {t('dashboard.newSearch.stepSubjectInfo.firstName')}
                          <span className="text-neutralColor80 ml-1">
                            {t('dashboard.newSearch.stepSubjectInfo.required')}
                          </span>
                        </span>
                      </FormLabel>
                      <FormControl>
                        <Input {...field} tabIndex={1} />
                      </FormControl>
                      <FormMessage data-name="firstName" />
                    </FormItem>
                  )}
                />
                <FormField
                  control={control}
                  name="middleName"
                  render={({ field }) => (
                    <FormItem className="w-full h-20">
                      <FormLabel>
                        <span className="text-neutralColor">
                          {t('dashboard.newSearch.stepSubjectInfo.middleName')}
                        </span>
                      </FormLabel>
                      <FormControl>
                        <Input {...field} tabIndex={2} />
                      </FormControl>
                      <FormMessage data-name="middleName" />
                    </FormItem>
                  )}
                />
                <FormField
                  control={control}
                  name="lastName"
                  render={({ field }) => (
                    <FormItem className="w-full h-20">
                      <FormLabel>
                        <span className="text-neutralColor">
                          {t('dashboard.newSearch.stepSubjectInfo.lastName')}
                          <span className="text-neutralColor80 ml-1">
                            {t('dashboard.newSearch.stepSubjectInfo.required')}
                          </span>
                        </span>
                      </FormLabel>
                      <FormControl>
                        <Input {...field} tabIndex={3} />
                      </FormControl>
                      <FormMessage data-name="lastName" />
                    </FormItem>
                  )}
                />
                <FormField
                  control={control}
                  name="alias"
                  render={({ field }) => (
                    <FormItem className="w-full h-20">
                      <FormLabel className="text-neutralColor">
                        {t('dashboard.newSearch.stepSubjectInfo.alias')}
                      </FormLabel>
                      <FormControl>
                        <Input {...field} tabIndex={4} />
                      </FormControl>
                      <FormMessage data-name="alias" />
                      <p className="text-sm text-neutralColor60 mt-1">
                        {t('dashboard.newSearch.stepSubjectInfo.aliasSubText')}
                      </p>
                    </FormItem>
                  )}
                />
              </div>
              <div className="flex flex-col md:flex-row gap-6 my-2">
                <FormField
                  control={control}
                  name="email"
                  render={({ field }) => (
                    <FormItem className="w-full h-20">
                      <FormLabel className="text-neutralColor">
                        <span className="text-neutralColor">
                          {t('dashboard.newSearch.stepSubjectInfo.email')}
                          <span className="text-neutralColor80 ml-1">
                            {t('dashboard.newSearch.stepSubjectInfo.required')}
                          </span>
                        </span>
                      </FormLabel>
                      <FormControl>
                        <Input {...field} tabIndex={5} />
                      </FormControl>
                      <FormMessage data-name="email" />
                    </FormItem>
                  )}
                />
                <FormField
                  control={control}
                  name="phoneNumber"
                  render={({ field }) => (
                    <FormItem className="w-full">
                      <FormLabel className="text-neutralColor">
                        {t('dashboard.newSearch.stepSubjectInfo.phoneNumber')}
                      </FormLabel>
                      <FormControl>
                        <PhoneInputWithCountry
                          className="w-full h-11 border border-neutralColor30 bg-baseColorHighlight rounded-md text-sm shadow-sm px-3 py-1 tex"
                          {...field}
                          name="phoneNumber"
                          tabIndex={6}
                          defaultCountry="US"
                          limitMaxLength={true}
                          control={control}
                        />
                      </FormControl>
                      <FormMessage data-name="phoneNumber" />
                    </FormItem>
                  )}
                />
                <FormField
                  control={control}
                  name="dob"
                  render={({ field }) => (
                    <FormItem className="w-full h-20">
                      <FormLabel>
                        <span className="text-neutralColor">
                          {t('dashboard.newSearch.stepSubjectInfo.ageApprox.')}
                        </span>
                      </FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          placeholder=""
                          ref={dateFormat}
                          tabIndex={7}
                        />
                      </FormControl>
                      <FormMessage data-name="dob" />
                    </FormItem>
                  )}
                />
              </div>
            </div>

            <div className="flex flex-col gap-4 mt-5 lg:mt-0">
              <p className="text-base font-medium mb-2 lg:mb-0">
                {t(
                  'dashboard.newSearch.stepSubjectInfo.professionalInformation',
                )}
              </p>
              <div className="flex lg:flex-row flex-col gap-6 mb-2">
                <FormField
                  control={control}
                  name="companyName"
                  render={({ field }) => (
                    <FormItem className="w-full h-20">
                      <FormLabel>
                        {t('dashboard.newSearch.stepSubjectInfo.company')}
                      </FormLabel>
                      <FormControl>
                        <Input {...field} tabIndex={8} />
                      </FormControl>
                      <FormMessage data-name="companyName" />
                    </FormItem>
                  )}
                />
                <FormField
                  control={control}
                  name="collage"
                  render={({ field }) => (
                    <FormItem className="w-full">
                      <FormLabel className="text-neutralColor">
                        {t('dashboard.newSearch.stepSubjectInfo.college')}
                      </FormLabel>
                      <FormControl>
                        <Input {...field} tabIndex={9} />
                      </FormControl>
                      <FormMessage data-name="collage" />
                    </FormItem>
                  )}
                />
              </div>
            </div>

            <div className="flex flex-col gap-4">
              <p className="text-base font-medium">
                {t('dashboard.newSearch.stepSubjectInfo.location')}
              </p>
              <div className="flex lg:flex-row flex-col gap-6 mb-2">
                <FormField
                  control={control}
                  name="countryLongName"
                  render={({ field }) => (
                    <FormItem className="w-full h-20">
                      <FormLabel>
                        <span className="text-neutralColor">
                          {t('dashboard.newSearch.stepSubjectInfo.country')}
                          <span className="text-neutralColor80 ml-1">
                            {t('dashboard.newSearch.stepSubjectInfo.required')}
                          </span>
                        </span>
                      </FormLabel>
                      <FormControl>
                        <Autocomplete
                          {...field}
                          placeholder={t(
                            'dashboard.newSearch.stepSubjectInfo.enterCountry',
                          )}
                          language="en"
                          tabIndex={10}
                          className="flex h-11 w-full rounded-md border border-neutralColor30 bg-baseColorHighlight px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50"
                          apiKey={key}
                          options={{ types: ['(regions)'] }}
                          onPlaceSelected={(place) => {
                            setValue(
                              'countryLongName',
                              place.address_components[0].long_name
                                ? place.address_components[0].long_name
                                : '',
                              { shouldValidate: true, shouldDirty: true },
                            )
                            setCountryShortName(
                              place.address_components[0].short_name
                                ? place.address_components[0].short_name
                                : '',
                            )
                          }}
                        />
                      </FormControl>
                      <FormMessage data-name="countryLongName" />
                    </FormItem>
                  )}
                />
                <FormField
                  control={control}
                  name="stateLongName"
                  render={({ field }) => (
                    <FormItem className="w-full h-20">
                      <FormLabel>
                        <span className="text-neutralColor">
                          {t('dashboard.newSearch.stepSubjectInfo.state')}
                          <span className="text-neutralColor80 ml-1">
                            {t('dashboard.newSearch.stepSubjectInfo.required')}
                          </span>
                        </span>
                      </FormLabel>
                      <FormControl>
                        <Autocomplete
                          {...field}
                          placeholder={t(
                            'dashboard.newSearch.stepSubjectInfo.enterState',
                          )}
                          language="en"
                          tabIndex={11}
                          className="flex h-11 w-full rounded-md border border-neutralColor30 bg-baseColorHighlight px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-primaryColor5 file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50"
                          apiKey={key}
                          options={{ types: ['(regions)'] }}
                          onPlaceSelected={(place) => {
                            setValue(
                              'stateLongName',
                              place.address_components[0].long_name,
                            )
                            setStateShortName(
                              place.address_components[0].short_name,
                            )
                          }}
                        />
                      </FormControl>
                      <FormMessage data-name="stateLongName" />
                    </FormItem>
                  )}
                />
                <FormField
                  control={control}
                  name="cityLongName"
                  render={({ field }) => (
                    <FormItem className="w-full h-20">
                      <FormLabel>
                        <span className="text-neutralColor">
                          {t('dashboard.newSearch.stepSubjectInfo.city')}
                          <span className="text-neutralColor80 ml-1">
                            {t('dashboard.newSearch.stepSubjectInfo.required')}
                          </span>
                        </span>
                      </FormLabel>
                      <FormControl>
                        <Autocomplete
                          {...field}
                          language="en"
                          placeholder={t(
                            'dashboard.newSearch.stepSubjectInfo.enterCity',
                          )}
                          tabIndex={12}
                          className="flex h-11 w-full rounded-md border border-neutralColor30 bg-baseColorHighlight px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-primaryColor5 file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50"
                          apiKey={key}
                          options={{ types: ['(cities)'] }}
                          onPlaceSelected={(place) => {
                            setValue(
                              'cityLongName',
                              place.address_components[0].long_name,
                            )
                            setCityShortName(
                              place.address_components[0].short_name,
                            )
                          }}
                        />
                      </FormControl>
                      <FormMessage data-name="cityLongName" />
                    </FormItem>
                  )}
                />
              </div>
            </div>

            <div className="flex flex-col gap-4">
              <p className="text-base font-medium">
                {t('dashboard.newSearch.stepSubjectInfo.socialMediaAccounts')}
              </p>
              <SocialMediaSelector />
            </div>
            {!isMobile && (
              <div className="flex flex-col gap-2">
                <div className="flex justify-between items-center">
                  <div className="flex items-center justify-center">
                    <p>{t('dashboard.newSearch.common.inputStrength')}</p>
                    <p
                      className="text-textAccent ml-2 font-medium"
                      style={{ color: inputStrength.color }}
                    >
                      {inputStrength.text}
                    </p>
                  </div>
                  <span className="text-neutralColor60">
                    {t('dashboard.newSearch.common.estimateAccuracy')}
                    <span className="text-neutralColor font-bold ml-1">
                      {completePercentage.toFixed(0)} {'%'}
                    </span>
                  </span>
                </div>

                <Progress
                  value={completePercentage}
                  className="h-4"
                  color={inputStrength.color}
                />
              </div>
            )}
          </div>
        </form>
      </Form>
    </WeScreenV2>
  )
}
