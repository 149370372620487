import { SelectLabel } from '@radix-ui/react-select'
import { Sort } from 'iconsax-react'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { EmptyState } from '@/components/EmptyState/EmptyState'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
} from '@/components/ui/select'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { WeScreenV2 } from '@/components/WeScreen/WeScreenV2'
import { useAppSelector } from '@/hooks/redux'
import { useDisableBasedOnPermissions } from '@/hooks/useDisableBasedOnPermissions'
import { useRedirectBasedOnPermissions } from '@/hooks/useRedirectBasedOnPermissions'
import useSmallMobileMediaQuery from '@/hooks/useSmallMobileMediaQuery'
import { useSocialChecksEnterpriseQuery } from '@/store/api/Enterprise/Enterprise.query'
import type { SocialCheckItemWrapper } from '@/store/api/Enterprise/Enterprise.types'
import { useSocialChecksUserQuery } from '@/store/api/Person/Person.query'
import {
  useSearchHistoryEnterpriseQuery,
  useSearchHistoryUserQuery,
} from '@/store/api/SearchFace/SearchFace.query'
import type { searchHistoryItemWrapper } from '@/store/api/SearchFace/SearchFace.types'
import { useUserInfoQuery } from '@/store/api/Users/Users.query'
import { selectItsDefaultState } from '@/store/api/Users/Users.selector'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'

import { FaceMatchItem } from './Components/FaceMatchItem'
import { SocialAnalysisItem } from './Components/SocialAnalysisItem'
import { EnterpriseReportsScreen } from './EnterpriseReportsScreen'

export const ReportsScreen = () => {
  const { t } = useTranslation()
  const { hasViewReports } = useRedirectBasedOnPermissions()
  const isSmallMobile = useSmallMobileMediaQuery()
  const isDefaultState = useAppSelector(selectItsDefaultState)
  const [selectedSort, setSelectedSort] = useState<string>('All')

  useEffect(() => {
    hasViewReports()
  }, [isDefaultState])
  const permissions = useDisableBasedOnPermissions()

  const {
    data: socialCheckEnterpriseData,
    isLoading: isLoadingSecialChecks,
    isError: isErrorEnterpriseSocialCheck,
    refetch: refetchEnterpriseSocialCheck,
  } = useSocialChecksEnterpriseQuery()

  const {
    data: faceMatchEnterpriseData,
    isLoading: isLoadingHistoryQuery,
    isError: isErrorEnterpriseHistory,
    refetch: refetchEnterpriseHistory,
  } = useSearchHistoryEnterpriseQuery()

  const handleSortChange = (value: string) => {
    setSelectedSort(value)
  }

  const {
    data: socialCheckUserData,
    isLoading: isLoadingSecialChecksUser,
    isError: isErrorUserSocialCheck,
    refetch: refetchUserSocialCheck,
  } = useSocialChecksUserQuery()

  const {
    data: faceMatchUserData,
    isLoading: isLoadingHistoryUserQuery,
    isError: isErrorUserHistory,
    refetch: refetchUserHistory,
  } = useSearchHistoryUserQuery()

  const refetchAll = () => {
    if (
      faceMatchUserData &&
      socialCheckUserData &&
      faceMatchEnterpriseData &&
      socialCheckEnterpriseData
    ) {
      refetchUserHistory()
      refetchUserSocialCheck()
      refetchEnterpriseHistory()
      refetchEnterpriseSocialCheck()
    }
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      refetchAll()
    }, 300000)
    return () => clearInterval(intervalId)
  }, [
    faceMatchUserData,
    socialCheckUserData,
    faceMatchEnterpriseData,
    socialCheckEnterpriseData,
  ])

  const isLoadingEnterprise = isLoadingSecialChecks || isLoadingHistoryQuery
  const isLoadingUser = isLoadingHistoryUserQuery || isLoadingSecialChecksUser
  const isErrorEnterprise =
    isErrorEnterpriseSocialCheck || isErrorEnterpriseHistory
  const isErrorUserQuery = isErrorUserSocialCheck || isErrorUserHistory

  const allDataSortedEnterprise = useMemo(() => {
    const socialCheckItems = socialCheckEnterpriseData || []
    const faceMatchItems = faceMatchEnterpriseData || []
    const maxLength = Math.max(socialCheckItems.length, faceMatchItems.length)
    const sortedData = []

    for (let i = 0; i < maxLength; i++) {
      if (i < socialCheckItems.length) {
        sortedData.push(socialCheckItems[i])
      }
      if (i < faceMatchItems.length) {
        sortedData.push(faceMatchItems[i])
      }
    }

    return sortedData.sort(
      (a, b) =>
        new Date(b.creation_date).getTime() -
        new Date(a.creation_date).getTime(),
    )
  }, [socialCheckEnterpriseData, faceMatchEnterpriseData])

  const allDataSortedUser = useMemo(() => {
    const socialCheckItems = socialCheckUserData || []
    const faceMatchItems = faceMatchUserData || []
    const maxLength = Math.max(socialCheckItems.length, faceMatchItems.length)
    const sortedData = []

    for (let i = 0; i < maxLength; i++) {
      if (i < socialCheckItems.length) {
        sortedData.push(socialCheckItems[i])
      }
      if (i < faceMatchItems.length) {
        sortedData.push(faceMatchItems[i])
      }
    }

    return sortedData.sort(
      (a, b) =>
        new Date(b.creation_date).getTime() -
        new Date(a.creation_date).getTime(),
    )
  }, [socialCheckUserData, faceMatchUserData])

  const { data: userData } = useUserInfoQuery()

  return (
    <>
      {userData?.role === 'view_user' ? (
        <EnterpriseReportsScreen />
      ) : (
        <WeScreenV2
          title={t('dashboard.reportsScreen.reportHistory')}
          isLoading={isLoadingUser}
          isError={isErrorUserQuery}
          emptyStateText={t('dashboard.reportsScreen.emptyStateText')}
          useNextButton={false}
          useHeaderBackButton={false}
          customHeaderGap={0}
        >
          <div className="mt-4">
            <Tabs
              defaultValue={
                permissions.handleReports ? 'YourReports' : 'AccountReports'
              }
              className="flex flex-col gap-8 h-screen"
              onValueChange={refetchAll}
            >
              <div className="flex items-center justify-between">
                <TabsList className="flex bg-transparent gap-5 justify-start w-min max-lg:gap-2 p-0">
                  <TabsTrigger
                    value="YourReports"
                    disabled={!permissions.handleReports}
                  >
                    {isSmallMobile
                      ? t('dashboard.reportsScreen.your')
                      : t('dashboard.reportsScreen.yourReports')}
                  </TabsTrigger>
                  <TabsTrigger
                    value="AccountReports"
                    disabled={
                      !permissions.viewReports ||
                      isLoadingEnterprise ||
                      isErrorEnterprise
                    }
                  >
                    {isSmallMobile
                      ? t('dashboard.reportsScreen.company')
                      : t('dashboard.reportsScreen.companyReports')}
                  </TabsTrigger>
                </TabsList>
                <Select onValueChange={handleSortChange}>
                  <SelectTrigger
                    className="bg-primaryColor border-0 shadow-none w-10 h-10 flex items-center justify-center"
                    hiddenArrow
                  >
                    <div>
                      <Sort size={24} color={weCheckPalette.baseColor} />
                    </div>
                  </SelectTrigger>
                  <SelectContent className="bg-baseColorHighlight">
                    <SelectGroup>
                      <SelectLabel className="h-[34px] px-2 py-1.5 text-neutralColor text-textNormal text-base font-medium tracking-tight">
                        {t('dashboard.reportsScreen.filterBy')}
                      </SelectLabel>

                      <SelectItem value="All" className="text-neutralColor80">
                        {t('dashboard.reportsScreen.all')}
                      </SelectItem>
                      <SelectItem
                        value="FaceSearch"
                        className="text-neutralColor80"
                      >
                        {t('dashboard.reportsScreen.faceSearch')}
                      </SelectItem>
                      <SelectItem
                        value="AdverseMedia"
                        className="text-neutralColor80"
                      >
                        {t('dashboard.reportsScreen.adverseMedia')}
                      </SelectItem>
                      <SelectItem
                        value="AIAnalystReport"
                        className="text-neutralColor80"
                      >
                        {t('dashboard.reportsScreen.aIAnalystReport')}
                      </SelectItem>
                      <SelectItem
                        value="AIReport"
                        className="text-neutralColor80"
                      >
                        {t('dashboard.reportsScreen.aIReport')}
                      </SelectItem>
                      <SelectItem
                        value="WeCheckAssisted"
                        className="text-neutralColor80"
                      >
                        {t('dashboard.reportsScreen.weCheckAssisted')}
                      </SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
              <TabsContent value={'YourReports'}>
                <div className="flex flex-col ">
                  {selectedSort === 'AdverseMedia' &&
                    socialCheckUserData
                      ?.filter(
                        (AnalysisItem: SocialCheckItemWrapper) =>
                          AnalysisItem.data.reportType ===
                          'adverse_media_report',
                      )
                      .map((AnalysisItem: SocialCheckItemWrapper) => (
                        <SocialAnalysisItem
                          data={AnalysisItem.data}
                          key={`${AnalysisItem.data.id} userReports`}
                        />
                      ))}

                  {selectedSort === 'AIAnalystReport' &&
                    socialCheckUserData
                      ?.filter(
                        (AnalysisItem: SocialCheckItemWrapper) =>
                          AnalysisItem.data.reportType === 'ai_analyst_report',
                      )
                      .map((AnalysisItem: SocialCheckItemWrapper) => (
                        <SocialAnalysisItem
                          data={AnalysisItem.data}
                          key={`${AnalysisItem.data.id} userReports`}
                        />
                      ))}

                  {selectedSort === 'AIReport' &&
                    socialCheckUserData
                      ?.filter(
                        (AnalysisItem: SocialCheckItemWrapper) =>
                          AnalysisItem.data.reportType === 'ai_report',
                      )
                      .map((AnalysisItem: SocialCheckItemWrapper) => (
                        <SocialAnalysisItem
                          data={AnalysisItem.data}
                          key={`${AnalysisItem.data.id} userReports`}
                        />
                      ))}

                  {selectedSort === 'WeCheckAssisted' &&
                    socialCheckUserData
                      ?.filter(
                        (AnalysisItem: SocialCheckItemWrapper) =>
                          AnalysisItem.data.reportType === 'assisted_report',
                      )
                      .map((AnalysisItem: SocialCheckItemWrapper) => (
                        <SocialAnalysisItem
                          data={AnalysisItem.data}
                          key={`${AnalysisItem.data.id} userReports`}
                        />
                      ))}

                  {selectedSort === 'FaceSearch' &&
                    faceMatchUserData?.map(
                      (MatchItem: searchHistoryItemWrapper) => (
                        <FaceMatchItem
                          data={MatchItem.data}
                          key={`${MatchItem.data.idSearch} userReports`}
                        />
                      ),
                    )}
                  {selectedSort === 'All' &&
                    allDataSortedUser?.map((item) => {
                      if (item.type === 'socialCheck') {
                        return (
                          <SocialAnalysisItem
                            data={item.data}
                            key={`${item.data.id} userReports`}
                          />
                        )
                      } else {
                        return (
                          <FaceMatchItem
                            data={item.data}
                            key={`${item.data.idSearch} userReports`}
                          />
                        )
                      }
                    })}
                  {((selectedSort === 'All' && !allDataSortedUser.length) ||
                    (selectedSort === 'FaceSearch' &&
                      !faceMatchUserData?.length) ||
                    (selectedSort === 'AdverseMedia' &&
                      !socialCheckUserData?.some(
                        (item: SocialCheckItemWrapper) =>
                          item.data.reportType === 'adverse_media_report',
                      )) ||
                    (selectedSort === 'AIAnalystReport' &&
                      !socialCheckUserData?.some(
                        (item: SocialCheckItemWrapper) =>
                          item.data.reportType === 'ai_analyst_report',
                      )) ||
                    (selectedSort === 'AIReport' &&
                      !socialCheckUserData?.some(
                        (item: SocialCheckItemWrapper) =>
                          item.data.reportType === 'ai_report',
                      )) ||
                    (selectedSort === 'WeCheckAssisted' &&
                      !socialCheckUserData?.some(
                        (item: SocialCheckItemWrapper) =>
                          item.data.reportType === 'assisted_report',
                      ))) && (
                    <EmptyState
                      text={t('dashboard.reportsScreen.emptyStateCard')}
                    />
                  )}
                </div>
              </TabsContent>
              <TabsContent value={'AccountReports'}>
                <div className="flex flex-col ">
                  {selectedSort === 'AdverseMedia' &&
                    socialCheckEnterpriseData
                      ?.filter(
                        (AnalysisItem: SocialCheckItemWrapper) =>
                          AnalysisItem.data.reportType ===
                          'adverse_media_report',
                      )
                      .map((AnalysisItem: SocialCheckItemWrapper) => (
                        <SocialAnalysisItem
                          data={AnalysisItem.data}
                          key={`${AnalysisItem.data.id} userReports`}
                        />
                      ))}

                  {selectedSort === 'AIAnalystReport' &&
                    socialCheckEnterpriseData
                      ?.filter(
                        (AnalysisItem: SocialCheckItemWrapper) =>
                          AnalysisItem.data.reportType === 'ai_analyst_report',
                      )
                      .map((AnalysisItem: SocialCheckItemWrapper) => (
                        <SocialAnalysisItem
                          data={AnalysisItem.data}
                          key={`${AnalysisItem.data.id} userReports`}
                        />
                      ))}

                  {selectedSort === 'AIReport' &&
                    socialCheckEnterpriseData
                      ?.filter(
                        (AnalysisItem: SocialCheckItemWrapper) =>
                          AnalysisItem.data.reportType === 'ai_report',
                      )
                      .map((AnalysisItem: SocialCheckItemWrapper) => (
                        <SocialAnalysisItem
                          data={AnalysisItem.data}
                          key={`${AnalysisItem.data.id} userReports`}
                        />
                      ))}

                  {selectedSort === 'WeCheckAssisted' &&
                    socialCheckEnterpriseData
                      ?.filter(
                        (AnalysisItem: SocialCheckItemWrapper) =>
                          AnalysisItem.data.reportType === 'assisted_report',
                      )
                      .map((AnalysisItem: SocialCheckItemWrapper) => (
                        <SocialAnalysisItem
                          data={AnalysisItem.data}
                          key={`${AnalysisItem.data.id} userReports`}
                        />
                      ))}

                  {selectedSort === 'FaceSearch' &&
                    faceMatchEnterpriseData?.map(
                      (MatchItem: searchHistoryItemWrapper) => (
                        <FaceMatchItem
                          data={MatchItem.data}
                          key={`${MatchItem.data.idSearch} userReports`}
                        />
                      ),
                    )}
                  {selectedSort === 'All' &&
                    allDataSortedEnterprise.map((item) => {
                      if (item.type === 'socialCheck') {
                        return (
                          <SocialAnalysisItem
                            data={item.data}
                            key={`${item.data.id} userReports`}
                          />
                        )
                      } else {
                        return (
                          <FaceMatchItem
                            data={item.data}
                            key={`${item.data.idSearch} userReports`}
                          />
                        )
                      }
                    })}
                  {((selectedSort === 'All' &&
                    !allDataSortedEnterprise.length) ||
                    (selectedSort === 'FaceSearch' &&
                      !faceMatchEnterpriseData?.length) ||
                    (selectedSort === 'AdverseMedia' &&
                      !socialCheckEnterpriseData?.some(
                        (item: SocialCheckItemWrapper) =>
                          item.data.reportType === 'adverse_media_report',
                      )) ||
                    (selectedSort === 'AIAnalystReport' &&
                      !socialCheckEnterpriseData?.some(
                        (item: SocialCheckItemWrapper) =>
                          item.data.reportType === 'ai_analyst_report',
                      )) ||
                    (selectedSort === 'AIReport' &&
                      !socialCheckEnterpriseData?.some(
                        (item: SocialCheckItemWrapper) =>
                          item.data.reportType === 'ai_report',
                      )) ||
                    (selectedSort === 'WeCheckAssisted' &&
                      !socialCheckEnterpriseData?.some(
                        (item: SocialCheckItemWrapper) =>
                          item.data.reportType === 'assisted_report',
                      ))) && (
                    <EmptyState
                      text={t('dashboard.reportsScreen.emptyStateCard')}
                    />
                  )}
                </div>
              </TabsContent>
            </Tabs>
          </div>
        </WeScreenV2>
      )}
    </>
  )
}
