import type { EReportType } from '../Enterprise/Enterprise.types'

export interface CreateCheckoutSessionTypes {
  quantity: number
  type: 'find_people' | 'analyze_people'
}

export interface CreateCheckoutSessionReponse {
  info: {
    redirectUrl: string
  }
}

export interface PlanHistoryCrudresponse {
  message: string
  status: number
  info: PlanHistoryResponse
}

export interface PlanHistoryItem {
  creditType: EReportType
  date: string
  quantity: number
  totalPrice: number
  recordType: ECreditRecordType
  transactionDetails: {
    origin: EPaymentOrigin
    checkoutId?: string
    user?: string
    originalPrice: number
    discountPercentage: number
  }
}

export interface PlanHistoryResponse {
  page: number
  size: number
  pages: number
  totalItems: number
  currentItems: number
  rechargeHistory: PlanHistoryItem[]
}

export interface PlanHistoryRequest {
  page: number
  pageSize: number
}

export enum ECreditRecordType {
  ACCREDITATION = 'Accreditation',
  REFOUND = 'Refound',
  BURNED = 'Burned',
  DISCOUNT = 'Discount',
}
export enum EPaymentOrigin {
  STRIPE = 'stripe',
  BO = 'backoffice',
  API = 'api',
}
