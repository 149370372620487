import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import { Button } from '@/components/ui/button'
import { AppRoutes } from '@/constants/appRoutes'
import { catchWithSentry } from '@/hooks/useCatchWithSentry'
import useMobileMediaQuery from '@/hooks/useMobileMediaQuery'
import useSmallMobileMediaQuery from '@/hooks/useSmallMobileMediaQuery'
import useTabletMediaQuery from '@/hooks/useTabletMediaQuery'
import {
  type SocialCheckItem,
  type SocialCheckInputItem,
  ESocialCheckStatus,
  EWebhookEvent,
  EReportType,
} from '@/store/api/Enterprise/Enterprise.types'
import {
  useMakeBackgroundCheckOpenedMutation,
  useReDowloadPdfMutation,
} from '@/store/api/Person/Person.query'
import { formatDate } from '@/utils/date'

import { Chip } from '../../Home/components/Chip/Chip'

import { CancelReportDialog } from './CancelReportDialog'
import { ConsentFilesSheet } from './ConsentFilesSheet'
import { BurgerMenu } from './BurgerMenu'

interface SocialAnalysisItemTypes {
  data: SocialCheckItem
  isAccountReport?: boolean
}

export const SocialAnalysisItem = ({
  data,
  isAccountReport,
}: SocialAnalysisItemTypes) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const isMobile = useMobileMediaQuery()
  const isSmallMobile = useSmallMobileMediaQuery()
  const isTablet = useTabletMediaQuery()
  const dontIsDesktop = isTablet || isSmallMobile || isMobile
  const [reDowloadPDF, { isLoading }] = useReDowloadPdfMutation()
  const [makeBackgroundCheckOpened] = useMakeBackgroundCheckOpenedMutation()

  const extractInputInitials = (input: SocialCheckInputItem): string => {
    const fullname = input.name
    const words = fullname.split(' ')
    return (
      words?.[0]?.slice(0, 1) +
      ' ' +
      words?.[words?.length - 1]?.slice(0, 1)
    )?.toUpperCase()
  }

  const initials = useMemo(() => {
    if (!data.inputs.name) {
      return ''
    }
    const fullname = data.inputs.name
    const initials = fullname.split(' ')
    const firstInitial = initials[0] ? initials[0][0].toUpperCase() : ''
    const secondInitial = initials[2] ? initials[2][0].toUpperCase() : ''
    return `${firstInitial} ${secondInitial}`
  }, [data.inputs.name])

  const onPressDowload = async () => {
    if (data.pdf) {
      window.open(data.pdf, '_blank')
    } else if (data.status === 'PendingDownloadPdf') {
      try {
        const res = await reDowloadPDF({ id: data.id }).unwrap()
        if (res.info.url) {
          window.open(res.info.url, '_blank')
        }
      } catch (e) {
        catchWithSentry(e)
        console.error(e)
      }
    }
    try {
      await makeBackgroundCheckOpened({ id: data.id }).unwrap()
    } catch (e) {
      catchWithSentry(e)
      console.error(e)
    }
  }

  const isPending =
    data.status !== ESocialCheckStatus.Complete &&
    data.status !== ESocialCheckStatus.NotReleased
  const isConcent =
    data.status === ESocialCheckStatus.NotReleased &&
    data.complianceStatus !== EWebhookEvent.COMPLETED

  const hiddenDigitalReport = data.hiddenDigitalReport

  const isCancelled = data.status === ESocialCheckStatus.Cancelled

  const getReportName = (reportType: EReportType) => {
    switch (reportType) {
      case EReportType.AdverseMedia:
        return t('dashboard.reportsScreen.adverseMedia')
      case EReportType.Ai:
        return t('dashboard.reportsScreen.aIReport')
      case EReportType.AiAnalyst:
        return t('dashboard.reportsScreen.aIAnalystReport')
      case EReportType.Assited:
        return t('dashboard.reportsScreen.weCheckAssisted')
    }
  }

  const showAllButtons =
    !isPending && !isConcent && data.complianceSigned && !hiddenDigitalReport
  console.log(data.complianceSigned)
  return (
    <div className="bg-baseColor rounded-lg flex flex-row justify-normal p-3 h-[65px] w-full max-lg:h-[120px] max-lg:flex-col max-lg:gap-4 lg:items-center mb-5">
      <div className="flex w-1/2 max-lg:justify-between max-lg:w-full ">
        <div className="flex items-center gap-3">
          <Avatar>
            <AvatarImage />
            <AvatarFallback className="bg-baseColor text-neutralColor">
              {extractInputInitials(data.inputs)}
            </AvatarFallback>
          </Avatar>
          <div className="flex flex-col">
            <div className="flex items-center gap-2">
              <p className="text-neutralColor text-textNormal text-base font-normal tracking-wide text-nowrap">
                {data.inputs.name}
              </p>

              {isPending && !isCancelled && !dontIsDesktop && (
                <Chip blue text={t('dashboard.reportsScreen.inProgress')} />
              )}
              {!data.pdfViewed &&
                !isPending &&
                !isConcent &&
                !isCancelled &&
                !dontIsDesktop && (
                  <Chip green text={t('dashboard.reportsScreen.new')} />
                )}
              {isConcent &&
                !dontIsDesktop &&
                !isCancelled &&
                data.complianceStatus !== EWebhookEvent.COMPLETED && (
                  <Chip
                    yellow
                    text={t('dashboard.reportsScreen.pendingConsent')}
                  />
                )}
              {isCancelled && !dontIsDesktop && (
                <Chip red text={t('dashboard.reportsScreen.cancelled')} />
              )}
            </div>
            <p className="text-neutralColor text-sm font-normal tracking-wide">
              {data.inputs.email}
            </p>
          </div>
        </div>
        {isPending && !isCancelled && dontIsDesktop && (
          <Chip blue text={t('dashboard.reportsScreen.pending')} />
        )}
        {!data.pdfViewed &&
          !isPending &&
          !isConcent &&
          !isCancelled &&
          dontIsDesktop && (
            <Chip green text={t('dashboard.reportsScreen.new')} />
          )}
        {isConcent &&
          dontIsDesktop &&
          !isCancelled &&
          data.complianceStatus !== EWebhookEvent.COMPLETED && (
            <Chip yellow text={t('dashboard.reportsScreen.concentPending')} />
          )}
        {isCancelled && dontIsDesktop && (
          <Chip red text={t('dashboard.reportsScreen.cancelled')} />
        )}
      </div>

      <div className="flex lg:w-1/3 ">
        <div className="flex" />
        <div
          className={`flex flex-col w-full   ${
            !isAccountReport && 'gap-1.5'
          }  `}
        >
          <p className="text-neutralColor text-textNormal text-sm font-medium tracking-tight text-nowrap ">
            {getReportName(data.reportType)}
          </p>
          <div
            className={`flex flex-row gap-1 items-center ${
              isAccountReport && 'mb-[-4px]'
            }  `}
          >
            {isAccountReport && (
              <>
                <p className="text-neutralColor text-xs font-medium tracking-tight text-nowrap">
                  {data.requester.firstname} {data.requester.lastname}
                </p>
                <p className="mb-0.5">·</p>
              </>
            )}

            <p className="text-neutralColor text-xs font-medium tracking-tight">
              {formatDate(data.reportDate, 'MM/dd/yyyy')}
            </p>
          </div>
        </div>

        {!dontIsDesktop && (
          <div className="mr-2 flex flex-col gap-1">
            <p className="text-neutralColor text-textNormal text-sm font-medium tracking-tight text-nowrap ">
              {t('dashboard.reportsScreen.lastUpdate')}
            </p>
            <p className="text-neutralColor text-xs font-medium tracking-tight">
              {formatDate(data.lastUpdate, 'MM/dd/yyyy')}
            </p>
          </div>
        )}
        {dontIsDesktop && (
          <>
            {showAllButtons ? (
              <div className="flex w-3/12 justify-end">
                <BurgerMenu
                  data={data}
                  isLoading={isLoading}
                  onPressDownload={onPressDowload}
                  name={data.inputs.name}
                />
              </div>
            ) : (
              <div className="flex justify-end gap-2">
                {data.complianceSigned && (
                  <ConsentFilesSheet
                    data={data}
                    isLoading={isLoading}
                    variant={'default'}
                  />
                )}
                {!isPending && !isConcent && (
                  <>
                    <Button
                      asChild
                      size={'default'}
                      isLoading={isLoading}
                      variant="outline"
                      onClick={onPressDowload}
                      className="cursor-pointer"
                    >
                      <div className="flex flex-row gap-4">
                        <p>{t('dashboard.reportsScreen.download')}</p>
                      </div>
                    </Button>
                    {!hiddenDigitalReport && (
                      <Button
                        asChild
                        onClick={() =>
                          navigate(AppRoutes.reportGeneralInfo, {
                            state: data.id,
                          })
                        }
                        size={'default'}
                        isLoading={false}
                        variant={'default'}
                      >
                        <div className="flex flex-row gap-2">
                          <p>{t('dashboard.reportsScreen.view')}</p>
                        </div>
                      </Button>
                    )}
                  </>
                )}
                {isConcent && !isPending && (
                  <CancelReportDialog
                    name={data.inputs.name}
                    address={data.inputs.email}
                    initials={initials}
                    id={data.id}
                  />
                )}
              </div>
            )}
          </>
        )}
      </div>

      {!dontIsDesktop && (
        <>
          {showAllButtons ? (
            <div className="flex lg:w-1/3 md:justify-end">
              <BurgerMenu
                data={data}
                isLoading={isLoading}
                onPressDownload={onPressDowload}
                name={data.inputs.name}
              />
            </div>
          ) : (
            <div className="flex lg:w-1/3 md:justify-end gap-4">
              {data.complianceSigned && (
                <ConsentFilesSheet
                  data={data}
                  isLoading={isLoading}
                  variant={'default'}
                />
              )}
              {!isPending && !isConcent && (
                <>
                  {!hiddenDigitalReport && (
                    <Button
                      asChild
                      onClick={() =>
                        navigate(AppRoutes.reportGeneralInfo, {
                          state: data.id,
                        })
                      }
                      size={'default'}
                      isLoading={false}
                      variant={'default'}
                    >
                      <div className="flex lg:flex-row gap-2">
                        <p>{t('dashboard.reportsScreen.view')}</p>
                      </div>
                    </Button>
                  )}
                  <Button
                    asChild
                    size={'default'}
                    isLoading={isLoading}
                    variant="secondary"
                    onClick={onPressDowload}
                    className="cursor-pointer"
                  >
                    <div className="flex flex-row gap-4">
                      <p>{t('dashboard.reportsScreen.download')}</p>
                    </div>
                  </Button>
                </>
              )}
              {!isPending && isConcent && !dontIsDesktop && (
                <CancelReportDialog
                  name={data.inputs.name}
                  address={data.inputs.email}
                  initials={initials}
                  id={data.id}
                />
              )}
            </div>
          )}
        </>
      )}
    </div>
  )
}
