import { useTranslation } from 'react-i18next'

import { WeScreenV2 } from '@/components/WeScreen/WeScreenV2'

export const Lab = () => {
  const { t } = useTranslation()

  return (
    <WeScreenV2
      title="Laboratory"
      useNextButton={false}
      useHeaderBackButton={false}
    >
      <div className="p-6 bg-baseColorHighlight rounded-lg">
        <h1>Proximamente...</h1>
      </div>
    </WeScreenV2>
  )
}
