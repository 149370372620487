export const cleanNumberMask = (input: string) => {
  return input.replace(/[^\d]/g, '')
}

export const getSocialMediaType = (url: string) => {
  const platformRegex =
    /(x\.com|tiktok\.com|linkedin\.com|facebook\.com|instagram\.com|twitter\.com|pinterest\.com)/

  const match = url.match(platformRegex)
  if (match) {
    const result = match[1].replace('.com', '')
    if (result === 'x') {
      return 'twitter'
    } else {
      return result
    }
  } else {
    return null
  }
}

export const getUsernameFromUrl = (url: string) => {
  // Twitter / X
  if (url.includes('twitter.com') || url.includes('x.com')) {
    const parts = url.split('/')
    return parts[parts.length - 1]
  }

  // Facebook
  if (url.includes('facebook.com')) {
    const usernameStartIndex = url.indexOf('com/') + 4
    const usernameEndIndex = url.indexOf('/', usernameStartIndex)
    return url.substring(
      usernameStartIndex,
      usernameEndIndex !== -1 ? usernameEndIndex : undefined,
    )
  }

  // Instagram
  if (url.includes('instagram.com')) {
    const usernameStartIndex = url.indexOf('com/') + 4
    const usernameEndIndex = url.indexOf('/', usernameStartIndex)
    return url.substring(
      usernameStartIndex,
      usernameEndIndex !== -1 ? usernameEndIndex : undefined,
    )
  }

  // Reddit
  if (url.includes('reddit.com')) {
    const parts = url.split('/')
    return parts[parts.length - 2]
  }

  // LinkedIn
  if (url.includes('linkedin.com')) {
    const usernameStartIndex = url.indexOf('in/') + 3
    const usernameEndIndex = url.indexOf('/', usernameStartIndex)
    return url.substring(
      usernameStartIndex,
      usernameEndIndex !== -1 ? usernameEndIndex : undefined,
    )
  }

  // Pinterest
  if (url.includes('pinterest.com')) {
    const parts = url.split('/')
    const username = parts[parts.length - 1] || parts[parts.length - 2]
    return username
  }

  // tiktok
  if (url.includes('tiktok.com')) {
    const parts = url.split('/')
    return parts[parts.length - 1]
  }

  // Default case
  return undefined
}
