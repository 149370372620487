import { Sort } from 'iconsax-react'

import { Button } from '@/components/ui/button'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import type { FilterByType } from '@/store/api/Person/Person.types'

export type FlaggedPopoverSortType = FilterByType | undefined
export const PlatformPopover = ({
  sortedBy,
  onChangeSort,
}: {
  sortedBy: FlaggedPopoverSortType
  onChangeSort: (sortBy: FlaggedPopoverSortType) => void
}) => {
  const handleChangeSort = (sort: FlaggedPopoverSortType) => {
    if (sort !== sortedBy) {
      onChangeSort(sort)
    }
  }

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          type="button"
          size={'icon'}
          variant={'secondary'}
          asChild
          className="w-10 h-10 bg-primaryColor"
        >
          <div>
            <Sort color="#FAFAFA" />
          </div>
        </Button>
      </PopoverTrigger>
      <PopoverContent style={{ width: 150 }}>
        <div className="tracking-widest border-b border-backgroundHighlight pb-2 px-1">
          Sort By
        </div>
        <div
          role="button"
          tabIndex={0}
          onClick={() => handleChangeSort(undefined)}
          className={`py-2 px-1 text-sm tracking-wider ${
            !sortedBy && 'bg-backgroundHighlight font-semibold'
          }`}
        >
          All
        </div>
        <div
          role="button"
          tabIndex={0}
          onClick={() => handleChangeSort('Flagged')}
          className={`py-2 px-1 text-sm tracking-wider ${
            sortedBy === 'Flagged' && 'bg-backgroundHighlight font-semibold'
          }`}
        >
          Flagged Posts
        </div>
        <div
          role="button"
          tabIndex={0}
          onClick={() => handleChangeSort('Negative')}
          className={`py-2 px-1 text-sm tracking-wider ${
            sortedBy === 'Negative' && 'bg-backgroundHighlight font-semibold'
          }`}
        >
          Negative Posts
        </div>
        <div
          role="button"
          tabIndex={0}
          onClick={() => handleChangeSort('Positive')}
          className={`py-2 px-1 text-sm tracking-wider ${
            sortedBy === 'Positive' && 'bg-backgroundHighlight font-semibold'
          }`}
        >
          Positive Posts
        </div>
      </PopoverContent>
    </Popover>
  )
}
