import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet'
import useMobileMediaQuery from '@/hooks/useMobileMediaQuery'

import { UseCaseCard } from './UseCaseCard'

export const BehaviorDetectionSheet = () => {
  const { t } = useTranslation()
  const data = [
    {
      name: t('dashboard.newSearch.focusedTopics.disparagingComments'),
      description: t(
        'dashboard.newSearch.focusedTopics.disparagingCommentsDescription',
      ),
    },
    {
      name: t('dashboard.newSearch.focusedTopics.threats'),
      description: t('dashboard.newSearch.focusedTopics.threatsDescription'),
    },
    {
      name: t('dashboard.newSearch.focusedTopics.suggestiveContent'),
      description: t(
        'dashboard.newSearch.focusedTopics.suggestiveContentDescription',
      ),
    },
    {
      name: t('dashboard.newSearch.focusedTopics.nudity'),
      description: t('dashboard.newSearch.focusedTopics.nudityDescription'),
    },
    {
      name: t('dashboard.newSearch.focusedTopics.suicideMentions'),
      description: t(
        'dashboard.newSearch.focusedTopics.suicideMentionsDescription',
      ),
    },
    {
      name: t('dashboard.newSearch.focusedTopics.drugImagery'),
      description: t(
        'dashboard.newSearch.focusedTopics.drugImageryDescription',
      ),
    },
    {
      name: t('dashboard.newSearch.focusedTopics.politicsGovernment'),
      description: t(
        'dashboard.newSearch.focusedTopics.politicsGovernmentDescription',
      ),
    },
    {
      name: t('dashboard.newSearch.focusedTopics.goryImages'),
      description: t('dashboard.newSearch.focusedTopics.goryImagesDescription'),
    },
    {
      name: t('dashboard.newSearch.focusedTopics.prejudice'),
      description: t('dashboard.newSearch.focusedTopics.prejudiceDescription'),
    },
    {
      name: t('dashboard.newSearch.focusedTopics.profanity'),
      description: t('dashboard.newSearch.focusedTopics.profanityDescription'),
    },
    {
      name: t('dashboard.newSearch.focusedTopics.drugAlcoholMention'),
      description: t(
        'dashboard.newSearch.focusedTopics.drugAlcoholMentionDescription',
      ),
    },
    {
      name: t('dashboard.newSearch.focusedTopics.gambling'),
      description: t('dashboard.newSearch.focusedTopics.gamblingDescription'),
    },
  ]
  const isMobile = useMobileMediaQuery()
  return (
    <Sheet>
      <SheetTrigger>
        <p className="text-dynamicBlue text-center text-base font-normal text-nowrap ml-1">
          {t('dashboard.newSearch.useCases.learnMore')}
        </p>
      </SheetTrigger>
      <SheetContent
        className="flex flex-col gap-8 max-h-full overflow-y-auto rounded-tl-2xl px-4"
        side={isMobile ? 'bottom' : 'right'}
      >
        <SheetHeader className="flex flex-col gap-3 mt-[-6px]">
          <SheetTitle className="text-textNormal text-xl font-medium tracking-wide text-left">
            {t('dashboard.newSearch.focusedTopics.sheetTitle')}
          </SheetTitle>
          <SheetDescription className="max-md:text-left">
            {t('dashboard.newSearch.focusedTopics.sheetDescription')}
          </SheetDescription>
        </SheetHeader>

        <div className="flex flex-col gap-8 justify-center ">
          {data?.map((Behavior, index) => {
            return (
              <UseCaseCard
                title={Behavior?.name}
                description={Behavior?.description}
                key={index}
              />
            )
          })}
        </div>
      </SheetContent>
    </Sheet>
  )
}
