import { RTKTags, api } from '@/store/api'

import Env from '../../../env/env'

import type {
  ContactUsRequest,
  LoginRequest,
  LoginResponse,
  OtpVerificationRequest,
  OtpVerificationResponse,
  RegisterRequest,
  RegisterResponse,
  SendOtpEmailRequest,
  UserInviteSingUpRequest,
  UserInviteSingUpResponse,
  changePasswordRequest,
  recoverPasswordRequest,
} from './Auth.types'

export const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<LoginResponse, LoginRequest>({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/auth/sign-in`,
        method: 'POST',
        body: {
          email: request.email,
          password: request.password,
        },
      }),
    }),
    register: builder.mutation<RegisterResponse, RegisterRequest>({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/auth/sign-up`,
        method: 'POST',
        body: {
          email: request.email,
          password: request.password,
        },
      }),
    }),
    contactUs: builder.mutation<null, ContactUsRequest>({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/forms/public-contact-us`,
        method: 'POST',
        body: {
          name: request.name,
          email: request.email,
          message: request.message,
        },
      }),
    }),
    recoverPassword: builder.mutation<null, recoverPasswordRequest>({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/auth/recover-password`,
        method: 'POST',
        body: {
          email: request.email,
        },
      }),
    }),
    changePassword: builder.mutation<null, changePasswordRequest>({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/auth/change-password`,
        method: 'POST',
        body: {
          token: request.token,
          newPassword: request.newPassword,
        },
      }),
    }),
    logout: builder.mutation<void, void>({
      query: () => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/health`,
        method: 'GET',
      }),
      invalidatesTags: [
        RTKTags.userInfo,
        RTKTags.userHistory,
        RTKTags.Home,
        RTKTags.enterpriseUsers,
        RTKTags.monitoringCenter,
        RTKTags.historyEnterprice,
        RTKTags.enterpriseInfo,
        RTKTags.enterpriseUsers,
      ],
    }),
    inviteSingUp: builder.mutation<
      UserInviteSingUpResponse,
      UserInviteSingUpRequest
    >({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/auth/invite-sign-up`,
        method: 'POST',
        body: {
          firstname: request.firstname,
          lastname: request.lastname,
          password: request.password,
          token: request.token,
        },
      }),
    }),
    sendOtpEmail: builder.mutation<void, SendOtpEmailRequest>({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/auth/send-otp-email`,
        method: 'POST',
        body: {
          email: request.email,
          reason: request.reason,
        },
      }),
    }),
    otpVerification: builder.mutation<
      OtpVerificationResponse,
      OtpVerificationRequest
    >({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/auth/verify-otp-email`,
        method: 'POST',
        body: {
          otp: request.otp,
          reason: request.reason,
          email: request.email,
        },
      }),
    }),
  }),
})

export const {
  useLoginMutation,
  useRegisterMutation,
  useContactUsMutation,
  useRecoverPasswordMutation,
  useChangePasswordMutation,
  useLogoutMutation,
  useInviteSingUpMutation,
  useSendOtpEmailMutation,
  useOtpVerificationMutation,
} = authApi
