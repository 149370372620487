import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import {
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog'
import { AppRoutes } from '@/constants/appRoutes'

export const ContactUsAlertDialog = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  return (
    <AlertDialogContent>
      <AlertDialogHeader>
        <AlertDialogTitle>{t('dashboard.contactUs.thankYou')}</AlertDialogTitle>
        <AlertDialogDescription>
          {t('dashboard.contactUs.weReceivedYourMessage')}
        </AlertDialogDescription>
      </AlertDialogHeader>
      <AlertDialogFooter>
        <AlertDialogAction onClick={() => navigate(AppRoutes.dashboard)}>
          {t('dashboard.contactUs.continue')}
        </AlertDialogAction>
      </AlertDialogFooter>
    </AlertDialogContent>
  )
}
