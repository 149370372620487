import { zodResolver } from '@hookform/resolvers/zod'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'

import {
  AlertDialogHeader,
  AlertDialog,
  AlertDialogTrigger,
  AlertDialogContent,
  AlertDialogTitle,
  AlertDialogDescription,
  AlertDialogCancel,
  AlertDialogAction,
} from '@/components/ui/alert-dialog'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { catchWithSentry } from '@/hooks/useCatchWithSentry'
import { useInviteEnterpriseNewUserMutation } from '@/store/api/Enterprise/Enterprise.query'
import type { inviteMembersType } from '@/utils/schemas'
import { inviteMembersSchema } from '@/utils/schemas'

interface InviteMembersDialogTypes {
  availableRoles: string[] | undefined
}

export const InviteMembersDialog = ({
  availableRoles,
}: InviteMembersDialogTypes) => {
  const { t } = useTranslation()
  const [selectedRole, setSelectedRole] = useState<string>('')

  const handleRoleChange = (value: string) => {
    setSelectedRole(value)
  }

  const form = useForm<inviteMembersType>({
    resolver: zodResolver(inviteMembersSchema),
    defaultValues: {
      email: '',
    },
  })

  const { handleSubmit, control, formState, reset } = form

  const [inviteUser, { isLoading }] = useInviteEnterpriseNewUserMutation()
  const resetInputs = () => {
    reset()
    setSelectedRole('')
  }

  const onSubmit = async (data: any) => {
    const inviteRequest = { role: selectedRole, email: data.email }
    try {
      await inviteUser(inviteRequest).unwrap()
      toast.success(t('dashboard.team.invitation'))
    } catch (e) {
      catchWithSentry(e)
      if (e.data.code === 'EMAIL_DUP') {
        toast.error(t('dashboard.team.userIsAlreadyOnAnother'))
      } else {
        toast.error(t('dashboard.team.errorSendingInvitation'))
      }
      console.error(e)
    }
    resetInputs()
  }

  const isValid = formState.isValid

  return (
    <AlertDialog>
      <AlertDialogTrigger className="w-[81px] h-[38px] bg-primaryColor rounded-xl max-md:w-full text-staticWhite tracking-widest">
        {t('dashboard.team.invite')}
      </AlertDialogTrigger>
      <AlertDialogContent className="flex flex-col w-6/12 max-md:w-11/12">
        <AlertDialogHeader>
          <AlertDialogTitle className="text-[32px] font-medium tracking-wide max-md:text-left max-md:text-[30px]">
            {t('dashboard.team.inviteNewMembers')}
          </AlertDialogTitle>
          <AlertDialogDescription className="text-base font-medium  tracking-tight max-md:text-left">
            {t('dashboard.team.inviteNewMembersText')}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <Form {...form}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col gap-5"
          >
            <div className="flex gap-6 items-end justify-start max-md:flex-col">
              <FormField
                control={control}
                name="email"
                render={({ field }) => (
                  <FormItem className="w-full rounded-md flex flex-col ">
                    <FormLabel className="text-textNormal text-base font-medium tracking-tight">
                      {t('dashboard.team.emailAddress')}
                    </FormLabel>
                    <FormControl className="w-full focus:outline-none">
                      <Input
                        className="bg-transparent"
                        {...field}
                        placeholder={t('dashboard.team.email')}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
              <div className="w-full flex flex-col gap-1.5">
                <FormLabel className="text-textNormal text-base font-medium tracking-tight">
                  {t('dashboard.team.role')}
                </FormLabel>
                <Select onValueChange={handleRoleChange}>
                  <SelectTrigger className="flex h-11 w-full rounded-md border border-neutralColor30 bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50">
                    <SelectValue
                      placeholder={t('dashboard.team.selectUserRole')}
                    />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectLabel>{t('dashboard.team.roles')}</SelectLabel>
                      {availableRoles?.map((role) => (
                        <SelectItem
                          key={role}
                          className="h-11 p-2 text-sm text-textAccent font-medium"
                          value={role}
                        >
                          {(role === 'operational_user' && 'Operational') ||
                            (role === 'view_user' && 'Reader') ||
                            (role === 'manager' && 'Manager')}
                        </SelectItem>
                      ))}
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
            </div>

            <div className="flex gap-2 justify-end">
              <AlertDialogCancel
                className="px-5 py-5 rounded-lg border border-slate-200 bg-primaryColor5"
                onClick={resetInputs}
              >
                <p className="text-neutralColor text-sm font-medium tracking-widest">
                  {t('dashboard.team.cancel')}
                </p>
              </AlertDialogCancel>
              <AlertDialogAction
                className="px-5 py-5 rounded-lg max-sm:mt-2 bg-primaryColor"
                type="submit"
                disabled={isLoading || selectedRole === '' || !isValid}
              >
                <p className="text-sm font-medium tracking-widest">
                  {t('dashboard.team.sendInvite')}
                </p>
              </AlertDialogAction>
            </div>
          </form>
        </Form>
      </AlertDialogContent>
    </AlertDialog>
  )
}
