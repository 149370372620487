import { zodResolver } from '@hookform/resolvers/zod'
import { isEmpty } from 'ramda'
import React, { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'

import AddSocialMediaIcon from '@/assets/addSocialMediaIcon.svg?react'
import EditWithBorder from '@/assets/editWithBorder.svg?react'
import Facebook from '@/assets/socialMedia/Facebook.svg?react'
import Instagram from '@/assets/socialMedia/Instagram.svg?react'
import Linkedln from '@/assets/socialMedia/LinkedIn.svg?react'
import Pinterest from '@/assets/socialMedia/Pinterest.svg?react'
import TikTok from '@/assets/socialMedia/TikTok.svg?react'
import X from '@/assets/socialMedia/X.svg?react'
import { Divider } from '@/components/Divider/Divider.component'
import { Button } from '@/components/ui/button'
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Sheet, SheetTrigger, SheetContent } from '@/components/ui/sheet'
import { useAppSelector } from '@/hooks/redux'
import useMobileMediaQuery from '@/hooks/useMobileMediaQuery'
import { selectIsDarkMode } from '@/store/api/ui/ui.selector'
import type { LoadSocialMediaUrlSchemaType } from '@/utils/schemas'
import { loadSocialMediaUrlSchema } from '@/utils/schemas'
import { getSocialMediaType } from '@/utils/strings'

import type { SocialMediaLink } from './NewBackgroundCheckForm.types'
import { SocialMediaItem } from './SocialMediaItem'

interface LoadSocialMediaSheetTypes {
  socialMediaArray: SocialMediaLink[]
  setSocialMediaArray: (newArray: SocialMediaLink[]) => void
}

export const LoadSocialMediaSheet = ({
  socialMediaArray,
  setSocialMediaArray,
}: LoadSocialMediaSheetTypes) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const form = useForm<LoadSocialMediaUrlSchemaType>({
    resolver: zodResolver(loadSocialMediaUrlSchema),
    defaultValues: {
      socialMediaUrl: '',
    },
    mode: 'onChange',
  })
  const isDarkMode = useAppSelector(selectIsDarkMode)
  const isMobile = useMobileMediaQuery()
  const {
    control,
    resetField,
    formState: { isDirty },
  } = form

  const addSocialMediaUrl = (e: any) => {
    if (e) {
      e.preventDefault()
    }
    const socialMediaUrl = form.getValues().socialMediaUrl

    const socialMediaType = getSocialMediaType(socialMediaUrl) as
      | 'x'
      | 'tiktok'
      | 'linkedin'
      | 'facebook'
      | 'instagram'
      | 'twitter'
      | 'pinterest'

    if (socialMediaType) {
      const filteredArray = socialMediaArray.filter(
        (socialMedia) => socialMedia.type !== socialMediaType,
      )
      setSocialMediaArray([
        ...filteredArray,
        { type: socialMediaType, url: socialMediaUrl },
      ])
    } else {
      toast.error(t('feedback.toast.invalidURLError'))
    }
    resetField('socialMediaUrl')
  }

  const socialMediaTypes = useMemo(
    () => [
      { logo: Facebook, type: 'facebook' },
      { logo: Instagram, type: 'instagram' },
      { logo: Linkedln, type: 'linkedin' },
      { logo: TikTok, type: 'tiktok' },
      { logo: X, type: 'twitter' },
      { logo: Pinterest, type: 'pinterest' },
    ],
    [socialMediaArray],
  )

  const getSocialMediaUrl = (
    socialMedia:
      | 'x'
      | 'tiktok'
      | 'linkedin'
      | 'facebook'
      | 'instagram'
      | 'twitter'
      | 'pinterest'
      | string,
  ) => socialMediaArray.find((item) => item.type === socialMedia)?.url

  const deleteUrl = (url: string) => {
    const newArray = socialMediaArray.filter(
      (socialMedia) => socialMedia.url !== url,
    )
    if (url) {
      setSocialMediaArray(newArray)
    }
  }

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger>
        <div role="button">
          {isEmpty(socialMediaArray) && (
            <AddSocialMediaIcon color={isDarkMode ? '#ffffff' : '#1c2226'} />
          )}
          {!isEmpty(socialMediaArray) && (
            <div className="ml-3">
              <EditWithBorder color={isDarkMode ? '#ffffff' : '#1c2226'} />
            </div>
          )}
        </div>
      </SheetTrigger>
      <SheetContent
        className="bg-baseColorHighlight lg:w-1/3 flex flex-col gap-6 overflow-y-scroll"
        side={isMobile ? 'bottom' : 'right'}
      >
        <div className="flex flex-col gap-6">
          <h1 className="text-neutralColor text-[24px] mt-6">
            {t('dashboard.newSearch.common.socialMediaProfiles')}
          </h1>
          <p className="text-neutralColor80">
            {t('dashboard.newSearch.common.enterUrl')}
          </p>
        </div>
        <FormField
          control={control}
          name="socialMediaUrl"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="text-neutralColor">URL</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage data-name="socialMediaUrl" />
            </FormItem>
          )}
        />
        <div className="flex justify-end">
          <Button
            onClick={addSocialMediaUrl}
            className={isMobile ? 'w-full' : 'w-15'}
            disabled={!isDirty}
          >
            {t('dashboard.newSearch.common.add')}
          </Button>
        </div>

        {socialMediaTypes.map((socialMediaType, index) => (
          <div className="flex flex-col gap-4" key={index}>
            <SocialMediaItem
              key={`SocialMediaItem ${index}`}
              url={getSocialMediaUrl(socialMediaType.type)}
              Icon={socialMediaType.logo}
              deleteUrl={deleteUrl}
            />
            <Divider key={`Divider ${index}`} />
          </div>
        ))}
      </SheetContent>
    </Sheet>
  )
}
