import { compareVersions } from 'compare-versions'
import { initializeApp } from 'firebase/app'
import {
  fetchAndActivate,
  getRemoteConfig,
  getValue,
} from 'firebase/remote-config'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { firebaseConfig } from '@/constants/firebaseConfig'
import type { IForceUpdate } from '@/store/api/Config/Config.slice'
import { updateConfig } from '@/store/api/Config/Config.slice'

import Env from '../env/env'

const FETCH_INTERVAL_IN_MILLISECONDS = 1 * 60 * 1000 // 12 minutos

export const useRemoteConfigInit = () => {
  const dispatch = useDispatch()
  const app = initializeApp(firebaseConfig)
  const remoteConfig = getRemoteConfig(app)

  // seteado en 12min
  remoteConfig.settings.minimumFetchIntervalMillis =
    FETCH_INTERVAL_IN_MILLISECONDS

  remoteConfig.defaultConfig = {
    inMaintenance: 'false',
    forceUpdate: '{"version":"1.00.00","isForceUpdate":false}',
  }

  const activateAndUpdateValues = async () => {
    await fetchAndActivate(remoteConfig)
      .then(() => {})
      .catch((err) => {
        console.log(err)
      })

    const config = {
      inMaintenance: getValue(remoteConfig, 'inMaintenance').asBoolean(),
      forceUpdate: JSON.parse(
        getValue(remoteConfig, 'forceUpdate').asString(),
      ) as IForceUpdate,
      softUpdate: JSON.parse(
        getValue(remoteConfig, 'softUpdate').asString(),
      ) as IForceUpdate,
      allowedCompanyId: JSON.parse(
        getValue(remoteConfig, 'allowedCompanyId').asString(),
      ),
    }

    dispatch(updateConfig(config))

    const remoteConfigVersion = config.forceUpdate.version
    const localVersion = Env.APP_VERSION
    const isForceUpdateVersion = config.forceUpdate.isForceUpdate

    // if remoteConfigVersion > localVersion, reload the page
    if (
      compareVersions(remoteConfigVersion, localVersion) > 0 &&
      isForceUpdateVersion
    ) {
      window.location.reload()
    }
  }

  useEffect(() => {
    activateAndUpdateValues()

    const interval = setInterval(async () => {
      await activateAndUpdateValues()
    }, FETCH_INTERVAL_IN_MILLISECONDS)

    return () => clearInterval(interval)
  }, [])
}
