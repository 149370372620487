import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet'
import useMobileMediaQuery from '@/hooks/useMobileMediaQuery'
import type { listOfUseCasesInfo } from '@/store/api/NewSearch/NewSearch.types'

import { UseCaseCard } from './UseCaseCard'

interface UseCaseGuidelineSheetTypes {
  data?: listOfUseCasesInfo[]
  language?: string
}

export const UseCaseGuidelineSheet = ({
  data,
  language,
}: UseCaseGuidelineSheetTypes) => {
  const { t } = useTranslation()
  const isMobile = useMobileMediaQuery()
  return (
    <Sheet>
      <SheetTrigger>
        <p className="text-dynamicBlue text-center text-base font-normal text-nowrap ml-1">
          {t('dashboard.newSearch.useCases.learnMore')}
        </p>
      </SheetTrigger>
      <SheetContent
        className="flex flex-col gap-4 overflow-y-scroll md:rounded-tl-2xl md:rounded-tr-2xl p-8"
        side={isMobile ? 'bottom' : 'right'}
      >
        <SheetHeader className="flex flex-col gap-3 mt-[-6px]">
          <SheetTitle className="text-textNormal text-xl font-medium tracking-wide text-left">
            {t('dashboard.newSearch.useCases.sheetTitle')}
          </SheetTitle>
          <SheetDescription className="text-left">
            {t('dashboard.newSearch.useCases.sheetDescription')}
          </SheetDescription>
        </SheetHeader>

        <div className="flex flex-col gap-4 lg:gap-6 justify-center">
          {data?.map((useCase, index) => {
            const translation = useCase.translations.find(
              (t) => t.language === language,
            )
            return (
              <UseCaseCard
                title={translation?.name}
                description={translation?.description}
                key={index}
              />
            )
          })}
        </div>
      </SheetContent>
    </Sheet>
  )
}
