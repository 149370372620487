import { WeScreenV2 } from '@/components/WeScreen/WeScreenV2'
import { FaceSearch } from './FaceSearch'
import EmptyStateAsset from '@/assets/emptyState/emptyStateAlternativeAsset.png'
import { FeedBackScreen } from '../Home/components/FeedBackScreen/FeedBackScreen'
import { useUserInfoQuery } from '@/store/api/Users/Users.query'
import { selectFacialMatchingCredits } from '@/store/api/Users/Users.selector'
import { EmptyState } from '@/components/EmptyState/EmptyState'
import { Error404 } from '@/components/ErrorState/Error404'
import { useTranslation } from 'react-i18next'

export const FaceSearchFatherScreen = () => {
  const { t } = useTranslation()

  const {
    isLoading: isLoadingUserData,
    data: userData,
    facialMatchingCredits,
  } = useUserInfoQuery(undefined, {
    selectFromResult: ({ data, ...rest }) => ({
      ...rest,
      facialMatchingCredits: selectFacialMatchingCredits(data),
      data,
    }),
  })

  return (
    <WeScreenV2
      useNextButton={false}
      useHeaderBackButton={false}
      customHeaderGap={0}
      isLoading={isLoadingUserData}
    >
      {facialMatchingCredits ? (
        <FaceSearch />
      ) : (
        <Error404
          title={t('dashboard.error404.insufficientCredits')}
          text={t('dashboard.error404.insufficientCreditsText')}
        />
      )}
    </WeScreenV2>
  )
}
