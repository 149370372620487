import { Sort } from 'iconsax-react'

import { Button } from '@/components/ui/button'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import { useTranslation } from 'react-i18next'
export const FlaggedPopover = ({
  sortedBy = 'latest',
  onChangeSort,
}: {
  sortedBy: 'latest' | 'oldest'
  onChangeSort: (sortBy: 'latest' | 'oldest') => void
}) => {
  const { t } = useTranslation()
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button type="button" size={'icon'} variant={'default'} asChild>
          <div>
            <Sort color="#FAFAFA" />
          </div>
        </Button>
      </PopoverTrigger>
      <PopoverContent style={{ width: 150 }}>
        <div className="tracking-widest border-b border-backgroundHighlight pb-2 px-1">
          {t('dashboard.postList.sortBy')}
        </div>
        <div
          role="button"
          tabIndex={0}
          onClick={() => onChangeSort('latest')}
          className={`py-2 px-1 text-sm tracking-wider ${
            sortedBy === 'latest' && 'bg-backgroundHighlight font-semibold'
          }`}
        >
          {t('dashboard.postList.latest')}
        </div>
        <div
          role="button"
          tabIndex={0}
          onClick={() => onChangeSort('oldest')}
          className={`py-2 px-1 text-sm tracking-wider ${
            sortedBy === 'oldest' && 'bg-backgroundHighlight font-semibold'
          }`}
        >
          {t('dashboard.postList.oldest')}
        </div>
      </PopoverContent>
    </Popover>
  )
}
