import { api, RTKTags } from '@/store/api'

import Env from '../../../env/env'

import type {
  getStatsResponse,
  listOfUseCasesResponse,
  listPaginatedOfTopicsRequest,
  listPaginatedOfTopicsResponse,
  sendEmailWithComplianceDocumentRequest,
  startNewPersonSearchRequest,
} from './NewSearch.types'

export const planApi = api.injectEndpoints({
  endpoints: (builder) => ({
    listPaginatedOfTopics: builder.query<
      listPaginatedOfTopicsResponse,
      listPaginatedOfTopicsRequest
    >({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/topics`,
        method: 'GET',
        params: {
          page: request.page,
          pageSize: request.pageSize,
          enterprise: request.enterprise,
          status: request.status,
        },
      }),
    }),
    listOfUseCases: builder.query<listOfUseCasesResponse, void>({
      query: () => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/use-cases`,
        method: 'GET',
      }),
    }),
    sendEmailWithComplianceDocument: builder.mutation<
      void,
      sendEmailWithComplianceDocumentRequest
    >({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/subjects/${request.id}/compliance/send-email`,
        method: 'POST',
        body: {
          email: request.email,
          documentToSend: request.documentToSend,
          businessDocument: request.businessDocument,
        },
      }),
      invalidatesTags: [RTKTags.monitoringCenter],
    }),
    saveComplianceSignedPdf: builder.mutation<
      void,
      { document: FormData; id: string }
    >({
      query: ({ document, id }) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v2/subjects/${id}/compliance`,
        method: 'POST',
        body: document,
      }),
    }),
    startNewPersonSearch: builder.mutation<
      { info: { id: string } },
      startNewPersonSearchRequest
    >({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v2/subjects`,
        method: 'POST',
        body: { ...request },
      }),
      invalidatesTags: [RTKTags.userInfo, RTKTags.monitoringCenter],
    }),
    getStats: builder.query<getStatsResponse, void>({
      query: () => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/enterprises/stats`,
        method: 'GET',
      }),
      providesTags: [RTKTags.userInfo],
    }),
  }),
})

export const {
  useListPaginatedOfTopicsQuery,
  useListOfUseCasesQuery,
  useSendEmailWithComplianceDocumentMutation,
  useSaveComplianceSignedPdfMutation,
  useStartNewPersonSearchMutation,
  useGetStatsQuery,
} = planApi
