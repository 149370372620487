import { InfoCircle } from 'iconsax-react'
import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet'
import useMobileMediaQuery from '@/hooks/useMobileMediaQuery'
import useSmallMobileMediaQuery from '@/hooks/useSmallMobileMediaQuery'
import useTabletMediaQuery from '@/hooks/useTabletMediaQuery'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'

export const TeamLearnMoreSheet = () => {
  const { t } = useTranslation()
  const isMobile = useMobileMediaQuery()
  const isSmallMobile = useSmallMobileMediaQuery()
  const isTablet = useTabletMediaQuery()
  return (
    <Sheet>
      <SheetTrigger>
        {isMobile || isSmallMobile || isTablet ? (
          <InfoCircle
            size="20"
            color={weCheckPalette.dynamicBlue}
            variant="Bold"
          />
        ) : (
          <p className="text-dynamicBlue text-center text-base font-normal text-nowrap ml-1 hover:underline">
            {t('dashboard.team.learnMore')}
          </p>
        )}
      </SheetTrigger>
      <SheetContent
        className="flex flex-col gap-4 overflow-y-scroll md:rounded-tl-2xl px-4"
        side={isMobile ? 'bottom' : 'right'}
      >
        <SheetHeader className="flex flex-col gap-2 mt-[-6px]">
          <SheetTitle className="text-neutralColor text-textNormal text-xl font-medium tracking-wide text-left">
            {t('dashboard.team.roleGuide')}
          </SheetTitle>
          <SheetDescription className="text-neutralColor80 max-md:text-left">
            {t('dashboard.team.roleGuideText')}
          </SheetDescription>
        </SheetHeader>
        <div>
          <p className="text-base font-medium text-neutralColor80 pb-1">
            {t('dashboard.team.owner')}
          </p>
          <p className="text-sm font-medium text-neutralColor60">
            {t('dashboard.team.ownerText')}
          </p>
        </div>
        <div>
          <p className="text-base font-medium text-neutralColor80 pb-1">
            {t('dashboard.team.manager')}
          </p>
          <p className="text-sm font-medium text-neutralColor60">
            {t('dashboard.team.managerText')}
          </p>
        </div>
        <div>
          <p className="text-base font-medium text-neutralColor80 pb-1">
            {t('dashboard.team.operational')}
          </p>
          <p className="text-sm font-medium text-neutralColor60">
            {t('dashboard.team.operationalText')}
          </p>
        </div>
        <div>
          <p className="text-base font-medium text-neutralColor80 pb-1">
            {t('dashboard.team.readOnly')}
          </p>
          <p className="text-sm font-medium text-neutralColor60">
            {t('dashboard.team.readOnlyText')}
          </p>
        </div>
      </SheetContent>
    </Sheet>
  )
}
