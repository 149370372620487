import React from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'

import {
  AlertDialogHeader,
  AlertDialog,
  AlertDialogTrigger,
  AlertDialogContent,
  AlertDialogTitle,
  AlertDialogDescription,
  AlertDialogCancel,
  AlertDialogAction,
  AlertDialogFooter,
} from '@/components/ui/alert-dialog'
import { Avatar, AvatarFallback } from '@/components/ui/avatar'
import { catchWithSentry } from '@/hooks/useCatchWithSentry'
import {
  useDeleteEnterpriseUserMutation,
  useDeleteInvitationUserMutation,
  useInviteEnterpriseNewUserMutation,
} from '@/store/api/Enterprise/Enterprise.query'
import AvatarPending from '@/assets/Avatar-pending.svg?react'

interface removeMembersDialogTypes {
  name: string
  address: string
  id: string
}

export const RevokeInvitationDialog = ({
  name,
  address,
  id,
}: removeMembersDialogTypes) => {
  const { t } = useTranslation()

  const [deleteInvitationUser, { isLoading }] =
    useDeleteInvitationUserMutation()

  const handleDeleteUser = async () => {
    try {
      await deleteInvitationUser({ inviteMemberId: id }).unwrap()
      toast.success('Revoke invitation succefully')
    } catch (e) {
      catchWithSentry(e)
      toast.error(t('dashboard.team.error'))
    }
  }

  return (
    <AlertDialog>
      <AlertDialogTrigger className="w-full md:w-24 h-10 px-3 py-2 rounded-xl tracking-widest bg-primaryColor5 border border-primaryColor30 text-neutralColor">
        {t('dashboard.team.revoke')}
      </AlertDialogTrigger>
      <AlertDialogContent className="flex flex-col md:w-6/12 max-md:w-11/12">
        <AlertDialogHeader>
          <AlertDialogTitle className="text-neutralColor text-[32px] font-medium tracking-wide max-sm:text-[30px] max-sm:text-left">
            {t('dashboard.team.revokeInvitation')}
          </AlertDialogTitle>
          <AlertDialogDescription className="text-neutralColor80 text-sm font-medium tracking-tight max-sm:text-left">
            {t('dashboard.team.areYouSure')}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <div className="flex items-center ">
          <div className="pr-3">
            <AvatarPending />
          </div>
          <div className="flex flex-col">
            <p className="font-medium text-neutralColor text-textNormal text-base tracking-tight">{`${name}`}</p>
            <p className="font-medium text-neutralColor80 tracking-tight text-textAccent text-sm ">{`${address}`}</p>
          </div>
        </div>
        <AlertDialogFooter className="flex flex-row items-center gap-2 justify-center">
          <AlertDialogCancel className="px-5 py-6 rounded-lg max-sm:mt-2 max-sm:w-full">
            <p className="text-textNormal text-base font-medium tracking-widest">
              {t('dashboard.team.removalCancel')}
            </p>
          </AlertDialogCancel>
          <AlertDialogAction
            className="px-5 py-6 rounded-lg max-sm:mt-2 max-sm:w-full"
            onClick={handleDeleteUser}
            disabled={isLoading}
          >
            <p className="text-neutral-50 text-base font-medium tracking-widest ">
              {t('dashboard.team.confirm')}
            </p>
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
