import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@/components/ui/button'
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet'
import useMobileMediaQuery from '@/hooks/useMobileMediaQuery'
import type { SocialCheckItem } from '@/store/api/Enterprise/Enterprise.types'

import { ConsentFileItem } from './ConsentFileItem'

interface ConsentFilesSheetTypes {
  data: SocialCheckItem | undefined
  isLoading: boolean
  variant:
    | 'link'
    | 'default'
    | 'blue'
    | 'destructive'
    | 'outline'
    | 'secondary'
    | 'ghost'
    | null
    | undefined
  paddingLeft?: number
  customWidth?: string
  customJustifyContent?: string
}

export const ConsentFilesSheet = ({
  data,
  isLoading,
  variant,
  paddingLeft,
  customWidth,
  customJustifyContent,
}: ConsentFilesSheetTypes) => {
  const { t } = useTranslation()
  const isMobile = useMobileMediaQuery()

  const handleClick = async (url: string | undefined) => {
    if (url) {
      window.open(url, '_blank')
    }
  }

  const files = [
    data?.complianceSigned && {
      url: data.complianceSigned,
    },
    data?.summarySigned && { url: data.summarySigned },
    data?.disclosureSigned && {
      url: data.disclosureSigned,
    },
    data?.dynamicSigned && { url: data.dynamicSigned },
    data?.otherAuthorizationSigned && { url: data.otherAuthorizationSigned },
  ].filter(Boolean) as { url: string }[]

  return (
    <Sheet>
      <SheetTrigger disabled={isLoading} style={{ width: customWidth }}>
        <Button
          asChild
          variant={variant}
          style={{
            paddingLeft: paddingLeft,
            width: customWidth,
            justifyContent: customJustifyContent,
          }}
        >
          <div>
            <p>{t('dashboard.reportsScreen.dowloadConcent')}</p>
          </div>
        </Button>
      </SheetTrigger>
      <SheetContent
        className="flex flex-col gap-8"
        side={isMobile ? 'bottom' : 'right'}
      >
        <SheetHeader className="flex flex-col gap-5">
          <SheetTitle className="text-textNormal text-start text-xl font-medium tracking-wide">
            {t('dashboard.reportsScreen.sheetTitle')}
          </SheetTitle>
          <SheetDescription className="text-textAccent text-start text-base font-normal tracking-tight">
            {t('dashboard.reportsScreen.sheetSubtitle')}
          </SheetDescription>
        </SheetHeader>
        <div className="flex flex-col justify-center items-start">
          {files.map((file, index) => (
            <ConsentFileItem
              key={index}
              fileName={`${t('dashboard.reportsScreen.file')}${index + 1}`}
              onClick={async () => handleClick(file.url)}
              withoutDivider={index === 0}
            />
          ))}
        </div>
      </SheetContent>
    </Sheet>
  )
}
