import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useAppSelector } from '@/hooks/redux'
import { useRedirectBasedOnPermissions } from '@/hooks/useRedirectBasedOnPermissions'
import { useLazyPlanHistoryQuery } from '@/store/api/Plan/Plan.query'
import { selectItsDefaultState } from '@/store/api/Users/Users.selector'

import { HistoryPlanItem } from '../HistoryPlanItem/HistoryPlanItem'
import { WeScreenV2 } from '@/components/WeScreen/WeScreenV2'
import useScreenSize from '@/hooks/useScreenSize'
import { PlanHistoryItem } from '@/store/api/Plan/Plan.types'
import FlatList from 'flatlist-react'
import useMobileMediaQuery from '@/hooks/useMobileMediaQuery'
import { EmptyState } from '@/components/EmptyState/EmptyState'
import { LottieSpinner } from '@/components/LottieSpinner/LottieSpinner'

export const HistoryPlanComponent = () => {
  const { t } = useTranslation()
  const { hasHandleBilling } = useRedirectBasedOnPermissions()
  const isDefaultState = useAppSelector(selectItsDefaultState)
  const [currentPage, setCurrentPage] = useState(1)
  const { height: screenHeight, width: screenWidth } = useScreenSize()
  const isMobile = useMobileMediaQuery()
  const [allRechargeHistory, setAllRechargeHistory] = useState<
    PlanHistoryItem[]
  >([])

  useEffect(() => {
    hasHandleBilling()
  }, [isDefaultState])

  const [
    makeRequest,
    {
      data: historyPlanData,
      isLoading: isLoadingPlanHistoryFirstTime,
      isError: isErrorPlanHistory,
      isFetching: isFetchingPlanHistory,
    },
  ] = useLazyPlanHistoryQuery()

  const makeBeRequest = useCallback(
    (page: number) => {
      makeRequest({ page, pageSize: 10 })
    },
    [makeRequest],
  )

  useEffect(() => {
    makeBeRequest(currentPage)
  }, [makeBeRequest, currentPage])

  useEffect(() => {
    if (historyPlanData?.rechargeHistory) {
      setAllRechargeHistory((prevHistory) => [
        ...prevHistory,
        ...historyPlanData.rechargeHistory,
      ])
    }
  }, [historyPlanData])

  const fetchMoreOnPagination = () => {
    if (!isFetchingPlanHistory && hasMoreData) {
      const newPage = currentPage + 1
      setCurrentPage(newPage)
      makeBeRequest(newPage)
    }
  }

  const postCardHeight = useMemo(() => Math.floor(screenHeight), [screenHeight])
  const postItemHeight = useMemo(
    () => Math.floor(screenHeight / 2),
    [screenHeight],
  )

  const renderItem = (item: PlanHistoryItem, idx: string) => {
    return (
      <div id={idx}>
        <HistoryPlanItem data={item} />
      </div>
    )
  }

  const hasMoreData = useMemo(() => {
    const totalLoadedItems = allRechargeHistory.length
    return totalLoadedItems < (historyPlanData?.totalItems || 0)
  }, [allRechargeHistory, historyPlanData?.totalItems])

  const isLoadingFirstPage =
    (isFetchingPlanHistory && currentPage === 1) ||
    isLoadingPlanHistoryFirstTime

  return (
    <WeScreenV2
      title={t('dashboard.historyPlan.accountBalance')}
      isLoading={isLoadingFirstPage}
      isError={isErrorPlanHistory || (!isLoadingFirstPage && !historyPlanData)}
      emptyStateText={t('dashboard.historyPlan.emptyState')}
      useNextButton={false}
      useHeaderBackButton={false}
      customHeaderGap={0}
    >
      {!isLoadingFirstPage && allRechargeHistory.length > 0 && (
        <div
          style={{
            overflow: 'auto',
            height: `${postCardHeight}px`,
            paddingTop: 24,
          }}
          id="scrolling-container"
        >
          <FlatList
            list={allRechargeHistory}
            renderItem={renderItem}
            displayGrid
            minColumnWidth={
              isMobile ? `${screenWidth / 2}px` : `${screenWidth / 1.5}px`
            }
            renderWhenEmpty={() => (
              <div className="flex overflow-hidden items-center justify-center">
                <EmptyState text={t('dashboard.postList.emptyState')} />
              </div>
            )}
            pagination={{
              hasMore: hasMoreData,
              loadMore: fetchMoreOnPagination,
            }}
          />
        </div>
      )}
      {isLoadingFirstPage && (
        <div className="flex justify-center ">
          <LottieSpinner />
        </div>
      )}
    </WeScreenV2>
  )
}
